
export default function ({ store, from, redirect, route, app, req }) {
  const locale = store.state.i18n.locale
  const user = store.state.auth.user
  const userRoles = user ? user.roles : null
  const pageRoles = route.meta && route.meta[0] && route.meta[0].auth ? route.meta[0].auth : []

  if (pageRoles !== undefined) {
    if (user && pageRoles === 'false') {
      return redirect(app.localePath('index', locale))
    }

    if (!user && pageRoles === 'true') {
      return redirect(app.localePath('auth-login', locale))
    }

    if (Array.isArray(pageRoles) && pageRoles.length > 0) {
      if (user && userRoles) {
        const access = userRoles.filter(x => pageRoles.includes(x))

        if (access.length === 0) {
          return redirect(app.localePath('index', locale))
        }
      } else {
        return redirect(app.localePath('auth-login', locale))
      }
    }
  }

  if (from && app.localePath('auth-login', locale)) {
    store.dispatch('auth/pageToRedirect', from.fullPath)
  }

  return Promise.resolve()
}
