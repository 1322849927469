<template>
  <div v-if="show && getCount > 0 && !isOpenedCart" class="cartTrigger" @click.stop="$store.dispatch('page/isOpenedCartToggle')">
    <b-icon icon="shopping-cart" />
    <div class="cartCount">
      {{ getCount }}
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      show: false
    }
  },
  computed: {
    getCart () {
      return this.$store.state.page.cart
    },
    getCount () {
      return this.getCart && this.getCart.quantity ? this.getCart.quantity : 0
    },
    isOpenedCart () {
      return this.$store.state.page.isOpenedCart
    }
  },
  mounted () {
    if (process.browser) {
      window.addEventListener('scroll', this.handleScroll)
    }
  },
  destroyed () {
    if (process.browser) {
      window.removeEventListener('scroll', this.handleScroll)
    }
  },
  methods: {
    handleScroll () {
      if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        this.show = true
      } else {
        this.show = false
      }

      const element = document.getElementById('checkout-order-summary')
      if (element) {
        this.show = false
      }
    }
  }
}
</script>
