<template>
  <div class="menu-container">
    <div class="logo">
      <logo />
      <select-language />
    </div>
    <div class="menu-items is-hidden-touch">
      <nuxt-link v-if="!user" :to="localePath('auth-login')" class="menu-item">
        <span class="menu-title">
          {{ $t('header.sign_in') }}
        </span>
        <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38"><circle fill="#F3F3F3" cx="19" cy="19" r="19" /><path d="M19,12.5a2,2,0,1,1-2,2,2,2,0,0,1,2-2M19,11a3.5,3.5,0,1,0,3.5,3.5A3.5,3.5,0,0,0,19,11Z" /><path d="M19,20.5c3.47,0,6,2.3,6,3.5,0,.77-.11.82-.42,1-1.09.48-3.71.54-5.58.54s-4.49-.06-5.58-.54c-.31-.14-.42-.19-.42-1,0-1.2,2.53-3.5,6-3.5M19,19c-4.14,0-7.5,2.79-7.5,5s1,3,7.5,3,7.5-.79,7.5-3-3.36-5-7.5-5Z" /></svg>
      </nuxt-link>
      <b-dropdown v-else class="menu-item">
        <div slot="trigger" slot-scope="{ active }" class="avatar-button">
          <avatar profile icon-right :name="user.displayName || 'user'" :avatar-url="user.avatar" />
        </div>
        <b-dropdown-item v-if="check('user')" has-link>
          <nuxt-link :to="localePath('profile-orders')">
            {{ $t('header.myAccount') }}
          </nuxt-link>
        </b-dropdown-item>

        <b-dropdown-item v-if="check('seller')" has-link>
          <nuxt-link :to="localePath('admin-orders')">
            {{ $t('header.seller_panel') }}
          </nuxt-link>
        </b-dropdown-item>

        <b-dropdown-item @click.native.prevent="logout">
          {{ $t('actions.logout') }}
        </b-dropdown-item>
      </b-dropdown>
      <nuxt-link :to="user && check('seller') ? localePath('admin-messenger') : localePath('messenger')" class="menu-item">
        <span :class="countNewMessages ? 'menu-title has-text-weight-semibold' : 'menu-title'">
          {{ $t('messenger.title') }}
          <span v-if="countNewMessages">({{ countNewMessages }})</span>
        </span>
        <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38"><path d="M19,38A19,19,0,1,1,38,19,19,19,0,0,1,19,38ZM19,1.5A17.5,17.5,0,1,0,36.5,19,17.52,17.52,0,0,0,19,1.5Z" fill="#cbcbcb" /><path d="M12.3,25.22l3.77-1,.37-.09.37.09A9.31,9.31,0,0,0,19,24.5c4.14,0,7.5-2.69,7.5-6s-3.36-6-7.5-6-7.5,2.69-7.5,6a5.1,5.1,0,0,0,1.14,3.17l.46.59-.19.73-.61,2.23M11.54,27a1,1,0,0,1-1-1l.92-3.38A6.59,6.59,0,0,1,10,18.5c0-4.14,4-7.5,9-7.5s9,3.36,9,7.5S24,26,19,26a10.6,10.6,0,0,1-2.55-.31L12,26.83A1,1,0,0,1,11.54,27Z" /><path d="M16.28,16h5.44a.76.76,0,0,1,.75.75h0a.76.76,0,0,1-.75.75H16.28a.76.76,0,0,1-.75-.75h0A.76.76,0,0,1,16.28,16Z" /><path d="M16.28,19.5h5.44a.76.76,0,0,1,.75.75h0a.76.76,0,0,1-.75.75H16.28a.76.76,0,0,1-.75-.75h0A.76.76,0,0,1,16.28,19.5Z" /></svg>
      </nuxt-link>
      <div class="menu-item" @click.stop="$store.dispatch('page/isOpenedCartToggle')">
        <menu-cart />
      </div>
    </div>
    <div class="menu-button-container is-hidden-desktop" @click.prevent="toggleMenu">
      <div :class="$store.state.page.isMenuOpen ? 'menu-button open' : 'menu-button'">
        <span />
        <span />
        <span />
        <span />
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from 'marketplace-front-core/components/shared/Avatar'
import MenuCart from 'marketplace-front-core/components/shared/MenuCart'
import SelectLanguage from 'marketplace-front-core/components/shared/SelectLanguage'
import Logo from './Logo'

export default {
  components: { Avatar, Logo, MenuCart, SelectLanguage },
  data () {
    return {
      isMenuOpen: false
    }
  },
  computed: {
    countNewMessages () {
      const count = this.$store.state.websocket.unreadMessage?.unreadTickets || null
      return count && count > 0 ? count : null
    }
  },
  methods: {
    check (key) {
      if (this.user) {
        let availableRoles = []
        switch (key) {
          case 'seller':
            availableRoles = ['ROLE_SELLER_OWNER', 'ROLE_SELLER']
            break
          case 'user':
            availableRoles = ['ROLE_SUPER_ADMIN', 'ROLE_USER']
            break
        }

        const check = availableRoles.filter(val =>
          this.user.roles.includes(val)
        )

        return check.length > 0
      } else {
        return false
      }
    },
    toggleMenu () {
      this.$store.dispatch('page/isMenuOpen', !this.$store.state.page.isMenuOpen)
    }
  }
}
</script>
