<template>
  <div v-if="user" ref="page" class="admin">
    <div ref="adminHeader" class="admin-header" :class="{'show-sidebar': showSidebar}">
      <div class="admin-menu">
        <div class="admin-wrapper is-align-items-center">
          <div ref="wrapperLogo" class="admin-wrapper-left absolute" :class="{'show-sidebar': showSidebar}">
            <div ref="logo" class="admin-logo absolute">
              <b-icon icon="sidebar-menu" size="is-small" @click.native="toggleSidebar" />
              <nuxt-link :to="localePath('index')">
                <admin-logo />
              </nuxt-link>
            </div>
          </div>
          <div class="admin-wrapper-right" :class="{'show-sidebar': showSidebar}">
            <div ref="menu" class="admin-container">
              <ul class="admin-menu-nav">
                <li>
                  <nuxt-link :to="localePath('admin-orders')" :class="getActiveLink('admin-orders')">
                    <b-icon icon="orders" />
                    {{ $t('admin.order.title') }}
                  </nuxt-link>
                </li>
                <li>
                  <nuxt-link :to="localePath('messenger')" :class="getActiveLink('admin-messenger')">
                    <b-icon icon="messages" />
                    {{ $t('messenger.title') + (countNewMessages ? ` (${countNewMessages})` : '') }}
                  </nuxt-link>
                </li>
                <li>
                  <nuxt-link :to="localePath('admin-offer-list')" :class="getActiveLink('admin-offers')">
                    <b-icon icon="offers" />
                    {{ $t('admin.offer.list.title') }}
                  </nuxt-link>
                </li>
                <li>
                  <nuxt-link :to="localePath('admin-product-list')" :class="getActiveLink('admin-products')">
                    <b-icon icon="products" />
                    {{ $t('admin.product.list.title') }}
                  </nuxt-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="admin-bar">
        <div class="admin-wrapper is-align-items-center">
          <div class="admin-wrapper-left" :class="{'show-sidebar': showSidebar}">
            <div ref="back" class="admin-bar-back" style="display: none">
              <div v-if="$slots.back" class="admin-bar-back-button">
                <slot name="back" />
              </div>
            </div>
          </div>
          <div class="admin-wrapper-right" :class="{'show-sidebar': showSidebar}">
            <div class="admin-container">
              <div ref="containerBar" class="admin-container-bar">
                <div ref="title" class="admin-title">
                  <slot name="title" />
                </div>
                <div v-show="$slots.tabs || $slots.buttons || $slots.status" ref="adminContainerBarActions" class="admin-container-bar-actions">
                  <div class="admin-tabs-dropdown" :class="{'is-hidden': !$slots.tabs}">
                    <div ref="tabs" :class="['admin-tabs', {'disabled': loading}]">
                      <div v-for="(item, index) in tabs()" :key="index" :class="['admin-tab', {'active': checkActiveTab(index)}]" @click="setActiveTab(index)">
                        <render-node :value="item.componentOptions.children" />
                      </div>
                    </div>
                    <div ref="dropdown" style="display: none;">
                      <b-dropdown
                        v-if="tabs().length"
                        aria-role="list"
                        :value="activeValue"
                        expanded
                        class="admin-menu-dropdown"
                        @change="changeDropdown"
                      >
                        <template #trigger="{ active }">
                          <b-button :icon-right="active ? 'chevron-up' : 'chevron-down'">
                            <render-node :value="searchActiveNode()" />
                          </b-button>
                        </template>

                        <b-dropdown-item v-for="(item, index) in tabs()" :key="index" aria-role="listitem" :value="checkValueTab(index)">
                          <render-node :value="item.componentOptions.children" />
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </div>
                  <div ref="buttons" class="admin-buttons" :class="{'is-hidden': !$slots.buttons}">
                    <slot name="buttons" />
                  </div>
                  <div ref="status" class="admin-status" :class="{'is-hidden': !$slots.status }">
                    <slot name="status" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div ref="adminContentWrapper" class="admin-content-wrapper" style="padding-top: 123px;" :class="{'show-sidebar': showSidebar}">
      <div class="admin-wrapper">
        <div class="admin-wrapper-left" :class="{'show-sidebar': showSidebar}">
          <div v-if="showSidebar" ref="sidebar" class="admin-sidebar">
            <div ref="menuMobile" class="admin-menu">
              <div class="admin-logo">
                <b-icon icon="sidebar-menu" size="is-small" @click.native="toggleSidebar" />
                <admin-logo />
              </div>
            </div>
            <ul>
              <li class="group">
                {{ $t('admin.menu.transactions') }}
              </li>
              <li v-if="isOwner">
                <nuxt-link :to="localePath('admin')" :class="getActiveLink('admin-dashboard')">
                  <b-icon icon="dashboard" size="is-small" />
                  {{ $t('admin.menu.dashboard') }}
                </nuxt-link>
              </li>
              <li>
                <nuxt-link :to="localePath('admin-orders')" :class="getActiveLink('admin-orders')">
                  <b-icon icon="orders" size="is-small" />
                  {{ $t('admin.order.title') }}
                </nuxt-link>
              </li>
              <li>
                <nuxt-link :to="localePath('messenger')" :class="getActiveLink('admin-messenger')">
                  <b-icon icon="messages" size="is-small" />
                  {{ $t('messenger.title') + (countNewMessages ? ` (${countNewMessages})` : '') }}
                </nuxt-link>
              </li>
              <li>
                <nuxt-link :to="localePath('admin-clients-list')" :class="getActiveLink('admin-clients')">
                  <b-icon icon="clients" size="is-small" />
                  {{ $t('admin.clients.list.title') }}
                </nuxt-link>
              </li>
              <li>
                <nuxt-link :to="localePath('admin-bank-transfer')" :class="getActiveLink('admin-bankTransfer')">
                  <b-icon icon="transfers" size="is-small" />
                  {{ $t('admin.bankTransfer.title') }}
                </nuxt-link>
              </li>
              <li>
                <nuxt-link :to="localePath('admin-tradeCredit-list')" :class="getActiveLink('admin-tradeCreditList')">
                  <b-icon icon="transfers" size="is-small" />
                  {{ $t('admin.tradeCredits.tradeCredit') }}
                </nuxt-link>
              </li>

              <li class="group">
                {{ $t('admin.menu.management') }}
              </li>
              <li>
                <nuxt-link :to="localePath('admin-offer-list')" :class="getActiveLink('admin-offers')">
                  <b-icon icon="offers" size="is-small" />
                  {{ $t('admin.offer.list.title') }}
                </nuxt-link>
              </li>
              <li>
                <nuxt-link :to="localePath('admin-product-list')" :class="getActiveLink('admin-products')">
                  <b-icon icon="products" size="is-small" />
                  {{ $t('admin.product.list.title') }}
                </nuxt-link>
              </li>
              <li>
                <nuxt-link :to="localePath('admin-descriptionTemplates-list')" :class="getActiveLink('admin-descriptionTemplates')">
                  <b-icon icon="editor-text" size="is-small" />
                  {{ $t('admin.descriptionTemplates.title') }}
                </nuxt-link>
              </li>
              <li>
                <nuxt-link :to="localePath('admin-sizes-list')" :class="getActiveLink('admin-sizes')">
                  <b-icon icon="sizes" size="is-small" />
                  {{ $t('admin.sizes.list.title') }}
                </nuxt-link>
              </li>
              <li>
                <nuxt-link :to="localePath('admin-shipping')" :class="getActiveLink('admin-shipping')">
                  <b-icon icon="shipping" size="is-small" />
                  {{ $t('admin.shipping.title') }}
                </nuxt-link>
              </li>
              <li>
                <nuxt-link :to="localePath('admin-filters')" :class="getActiveLink('admin-filters')">
                  <b-icon icon="offers" size="is-small" />
                  {{ $t('admin.filters.title') }}
                </nuxt-link>
              </li>
              <li>
                <nuxt-link :to="localePath('admin-spreadsheet')" :class="getActiveLink('admin-spreadsheet')">
                  <b-icon icon="import-export" size="is-small" />
                  {{ $t('admin.spreadsheet.title') }}
                </nuxt-link>
              </li>
              <li>
                <nuxt-link :to="localePath('admin-coupons&groupDeal')" :class="getActiveLink('admin-couponsAndGroupDeal')">
                  <b-icon icon="deals" size="is-small" />
                  {{ $t('admin.menu.couponsAndGroupDeal') }}
                </nuxt-link>
              </li>
              <li>
                <nuxt-link :to="localePath('admin-addonGroups')" :class="getActiveLink('admin-addon-groups')">
                  <b-icon icon="offers" size="is-small" />
                  {{ $t('admin.addonGroups.title') }}
                </nuxt-link>
              </li>
              <li>
                <nuxt-link :to="localePath('admin-clientGroups')" :class="getActiveLink('admin-client-groups')">
                  <b-icon icon="clients" size="is-small" />
                  {{ $t('admin.clientGroups.title') }}
                </nuxt-link>
              </li>
              <li v-if="isOwner">
                <nuxt-link :to="localePath('admin-employees-list')" :class="getActiveLink('admin-employees')">
                  <b-icon icon="clients" size="is-small" />
                  {{ $t('admin.employees.title') }}
                </nuxt-link>
              </li>
              <li>
                <nuxt-link :to="localePath('admin-pricesOnQuantity')" :class="getActiveLink('admin-pricesOnQuantity')">
                  <b-icon icon="currency-usd" size="is-small" />
                  {{ $t('admin.menu.pricesOnQuantity') }}
                </nuxt-link>
              </li>
              <li>
                <nuxt-link :to="localePath('admin-cms-post')" :class="getActiveLink('admin-cms-post')">
                  <b-icon icon="edit" size="is-small" />
                  {{ $t('admin.cms.post.title') }}
                </nuxt-link>
              </li>
              <li>
                <nuxt-link :to="localePath('admin-cms-page')" :class="getActiveLink('admin-cms-page')">
                  <b-icon icon="edit" size="is-small" />
                  {{ $t('admin.cms.page.title') }}
                </nuxt-link>
              </li>
              <li>
                <nuxt-link :to="localePath('admin-marketing-list')" :class="getActiveLink('admin-marketing')">
                  <b-icon icon="mail" size="is-small" />
                  {{ $t('admin.marketing.marketing') }}
                </nuxt-link>
              </li>
              <li>
                <nuxt-link :to="localePath('admin-tools')" :class="getActiveLink('admin-tools')">
                  <b-icon icon="edit" size="is-small" />
                  {{ $t('admin.menu.tools') }}
                </nuxt-link>
              </li>

              <li class="group">
                {{ $t('admin.menu.myAccount') }}
              </li>
              <li>
                <nuxt-link :to="localePath('admin-store-edit')" :class="getActiveLink('admin-store-edit')">
                  <b-icon icon="store" size="is-small" />
                  {{ $t('admin.store.edit.title') }}
                </nuxt-link>
              </li>
              <li>
                <nuxt-link :to="localePath('admin-payment')" :class="getActiveLink('admin-payment')">
                  <b-icon icon="wallet" size="is-small" />
                  {{ $t('admin.payment.title') }}
                </nuxt-link>
              </li>
              <li>
                <nuxt-link :to="localePath('admin-invoice')" :class="getActiveLink('admin-invoice')">
                  <b-icon icon="sidebar-invoice" size="is-small" />
                  {{ $t('admin.invoice.title') }}
                </nuxt-link>
              </li>
              <li>
                <nuxt-link :to="localePath('admin-profile-edit')" :class="getActiveLink('admin-myAccount-edit')">
                  <b-icon icon="edit" size="is-small" />
                  {{ $t('myAccount.edit') }}
                </nuxt-link>
              </li>
              <li>
                <nuxt-link :to="localePath('admin-change-password')" :class="getActiveLink('admin-change-password')">
                  <b-icon icon="lock" size="is-small" />
                  {{ $t('myAccount.change_password') }}
                </nuxt-link>
              </li>
              <li>
                <nuxt-link :to="localePath('index')">
                  <b-icon icon="shopping-cart" size="is-small" />
                  {{ $t('admin.menu.backShop') }}
                </nuxt-link>
              </li>
              <li>
                <a @click="logout">
                  <b-icon icon="clear" size="is-small" />
                  {{ $t('actions.logout') }}
                </a>
              </li>
              <template v-if="showDeveloperMenu">
                <li class="group">
                  development
                </li>
                <li>
                  <a @click.prevent="change; $refs.iconsList.show()">
                    Ikonki
                  </a>
                </li>
              </template>
            </ul>
          </div>
        </div>
        <div class="admin-wrapper-right" :class="{'show-sidebar': showSidebar}">
          <b-loading :is-full-page="false" :active="loadingContent" />
          <div class="admin-container">
            <div ref="content" class="admin-content">
              <slot />
            </div>
          </div>
        </div>
      </div>
    </div>
    <icons-list v-if="showDeveloperMenu" ref="iconsList" />
  </div>
</template>

<script>
import IconsList from 'marketplace-front-core/assets/generate-icons/IconsList'
import $ from 'jquery'
import AdminLogo from '~/components/layout/AdminLogo'

export default {
  components: { IconsList, AdminLogo },
  props: {
    tab: {
      type: [String, Number],
      default: null
    },
    loading: {
      type: Boolean
    },
    loadingContent: {
      type: Boolean
    }
  },
  data () {
    return {
      title: '',
      showSidebar: false,
      showDeveloperMenu: process.env.NODE_ENV === 'development',
      activeValue: null,
      windowHeight: null,
      routeKey: null
    }
  },
  head () {
    const i18nHead = this.$nuxtI18nHead({ addSeoAttributes: true })
    return {
      title: this.title,
      htmlAttrs: {
        ...i18nHead.htmlAttrs
      },
      meta: [
        ...i18nHead.meta
      ],
      link: [
        ...this.metaLink(i18nHead.link)
      ]
    }
  },
  computed: {
    countNewMessages () {
      const count = this.$store.state.websocket.unreadMessage?.unreadTickets || null
      return count && count > 0 ? count : null
    },
    isOwner () {
      return this.user?.roles?.includes('ROLE_SELLER_OWNER') || false
    }
  },
  watch: {
    $route: {
      handler () {
        this.$nextTick(() => {
          this.title = this.convertSlotToText(this.$slots.title)
          this.routeKey = this.convertSlotToText(this.$slots.routeKey) || null
        })
      },
      deep: true,
      immediate: true
    },
    tab: {
      handler  (value) {
        if (this.activeValue !== value) {
          this.activeValue = value
        }
      },
      immediate: true
    }
  },
  mounted () {
    $(window).on('resize', e => this.resize())
    this.resize()
    if (this.showSidebar) {
      document.body.classList.add('is-noscroll-mobile')
    } else {
      document.body.classList.remove('is-noscroll-mobile')
    }
  },
  destroyed () {
    $(window).off('resize')
  },
  methods: {
    tabs () {
      return this.$slots.tabs ? this.$slots.tabs.filter(x => x.componentOptions && x.componentOptions.tag === 'admin-tab') : []
    },
    checkValueTab (index) {
      return this.tabs()[index] &&
        this.tabs()[index].componentOptions &&
        this.tabs()[index].componentOptions.propsData &&
        this.tabs()[index].componentOptions.propsData.value
        ? this.tabs()[index].componentOptions.propsData.value
        : null
    },
    checkActiveTab (index) {
      const value = this.checkValueTab(index)
      return value === this.activeValue
    },
    setActiveTab (index) {
      if (!this.loading) {
        this.activeValue = this.checkValueTab(index)
        this.$emit('update:tab', this.activeValue)
      }
    },
    searchActiveNode () {
      const find = this.tabs().find(x =>
        x &&
        x.componentOptions &&
        x.componentOptions.propsData &&
        x.componentOptions.propsData.value &&
        x.componentOptions.propsData.value === this.activeValue
      )

      return find ? find.componentOptions.children : null
    },
    changeDropdown (value) {
      if (!this.loading) {
        this.activeValue = value
        this.$emit('update:tab', this.activeValue)
      }
    },
    convertSlotToText (slot) {
      return slot
        ? slot
          .map(vnode => (vnode.text || vnode.elm.innerText))
          .join('')
          .replace(/\n/g, '')
          .replace(/\s\s+/g, '')
          .replace(/^\s+/, '')
          .replace(/\s+$/, '')
        : ''
    },
    getActiveLink (key) {
      return this.routeKey === key ? 'active' : ''
    },
    toggleSidebar () {
      const newValue = !this.showSidebar
      if (newValue) {
        this.windowHeight = document.body.scrollTop || document.documentElement.scrollTop
        document.body.classList.add('is-noscroll-mobile')
      } else {
        document.body.classList.remove('is-noscroll-mobile')
        const menuMobile = this.$refs.menuMobile
        if ($(menuMobile).is(':visible')) {
          window.scrollTo({ top: this.windowHeight })
        }
      }

      this.showSidebar = newValue

      this.$nextTick(() => {
        this.resize()
      })
    },
    resize () {
      const content = this.$refs.content
      const menu = this.$refs.menu
      const wrapperLogo = this.$refs.wrapperLogo
      const logo = this.$refs.logo
      const back = this.$refs.back
      const sidebar = this.$refs.sidebar

      $(this.$refs.adminContentWrapper).css({ 'padding-top': $(this.$refs.adminHeader).height() })
      $(sidebar).height($(window).height() - $(this.$refs.adminHeader).height())

      if (!this.showSidebar) {
        $(back).width('')
        $(logo).width('')

        const availableWidth = ($(window).width() - $(content).outerWidth()) / 2

        if (availableWidth > $(logo).width()) {
          $(wrapperLogo).addClass('absolute')
          $(logo).addClass('absolute')
          $(menu).removeClass('add-menu')
          $(logo).width(availableWidth)
        } else {
          $(wrapperLogo).removeClass('absolute')
          $(logo).removeClass('absolute')
          $(menu).addClass('add-menu')
          $(logo).width('')
        }

        if (availableWidth > $(back).width()) {
          $(back).show()
          $(back).width(availableWidth)
        } else {
          $(back).hide()
        }
      } else {
        $(back).show()
        $(back).width('')
        $(logo).width('')
        $(wrapperLogo).removeClass('absolute')
        $(logo).removeClass('absolute')
        $(menu).removeClass('add-menu')
      }

      this.showHideTabDropdown()
    },
    showHideTabDropdown () {
      const containerBar = this.$refs.containerBar
      const title = this.$refs.title
      const tabs = this.$refs.tabs
      const dropdown = this.$refs.dropdown
      const buttons = this.$refs.buttons
      const status = this.$refs.status
      const adminContainerBarActions = this.$refs.adminContainerBarActions
      let availableWidth

      $(tabs).show()
      $(dropdown).hide()
      // $(tabs).width('')

      if ($(window).width() < 1024) {
        availableWidth = $(adminContainerBarActions).width()
      } else {
        availableWidth = $(containerBar).outerWidth() - $(title).outerWidth() - $(buttons).outerWidth() - $(status).outerWidth()
      }

      if (Math.floor($(tabs).width()) <= Math.floor(availableWidth)) {
        $(tabs).show()
        $(dropdown).hide()
      } else {
        $(tabs).hide()
        $(dropdown).show()
      }

      // $(tabs).width(availableWidth)
    }

  }
}
</script>
