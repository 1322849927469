<template>
  <footer :class="{'footer': !isCheckoutPage, 'no-footer': isCheckoutPage, 'is-pl': currentLang === 'pl' }">
    <div v-if="!isCheckoutPage" class="container main-container">
      <div class="columns is-vcentered m-b-0 is-desktop">
        <div class="column">
          <div class="columns is-centered">
            <div class="column footer-menu is-narrow">
              <h5>{{ $t('project.layout.footer.menu.info.title') }}</h5>
              <ul>
                <template v-if="currentLang === 'it'">
                  <li>
                    <nuxt-link :to="localePath('static-montage')">
                      {{ $t('project.layout.footer.menu.info.instruction') }}
                    </nuxt-link>
                  </li>
                  <li>
                    <nuxt-link :to="localePath({name: 'cms-postPage', params: {slug: 'domande-frequenti'}})">
                      {{ $t('project.layout.footer.menu.info.faq') }}
                    </nuxt-link>
                  </li>
                  <li>
                    <nuxt-link :to="localePath({name: 'cms-postPage', params: {slug: '2341255333'}})">
                      {{ $t('project.layout.footer.menu.info.imprint') }}
                    </nuxt-link>
                  </li>
                  <template v-if="project === 'schuette'">
                    <li v-if="slugRegulations">
                      <nuxt-link :to="localePath({name: 'cms-postPage', params: {slug: slugRegulations}})">
                        Termini e condizioni generali
                      </nuxt-link>
                    </li>
                    <li v-if="slugRegulations">
                      <nuxt-link :to="localePath({name: 'cms-postPage', params: {slug: '789032'}})">
                        Informativa sulla privacy
                      </nuxt-link>
                    </li>
                    <li>
                      <nuxt-link :to="localePath({name: 'cms-postPage', params: {slug: '267843'}})">
                        Diritto di recesso
                      </nuxt-link>
                    </li>
                  </template>
                  <template v-else>
                    <li v-if="slugRegulations">
                      <nuxt-link :to="localePath({name: 'cms-postPage', params: {slug: slugRegulations}})">
                        {{ $t('project.layout.footer.menu.info.regulations') }}
                      </nuxt-link>
                    </li>
                  </template>
                </template>
                <template v-if="currentLang === 'es'">
                  <li>
                    <nuxt-link :to="localePath('static-montage')">
                      {{ $t('project.layout.footer.menu.info.instruction') }}
                    </nuxt-link>
                  </li>
                  <li>
                    <nuxt-link :to="localePath({name: 'cms-postPage', params: {slug: 'preguntas-frecuentes-faq'}})">
                      {{ $t('project.layout.footer.menu.info.faq') }}
                    </nuxt-link>
                  </li>
                  <li>
                    <nuxt-link :to="localePath({name: 'cms-postPage', params: {slug: '25454554'}})">
                      {{ $t('project.layout.footer.menu.info.imprint') }}
                    </nuxt-link>
                  </li>
                  <template v-if="project === 'schuette'">
                    <li v-if="slugRegulations">
                      <nuxt-link :to="localePath({name: 'cms-postPage', params: {slug: slugRegulations}})">
                        Términos de servicio
                      </nuxt-link>
                    </li>
                    <li v-if="slugRegulations">
                      <nuxt-link :to="localePath({name: 'cms-postPage', params: {slug: '5458752360'}})">
                        Protección de Datos
                      </nuxt-link>
                    </li>
                    <li>
                      <nuxt-link :to="localePath({name: 'cms-postPage', params: {slug: '7455455'}})">
                        Derecho de devolución
                      </nuxt-link>
                    </li>
                  </template>
                  <template v-else>
                    <li v-if="slugRegulations">
                      <nuxt-link :to="localePath({name: 'cms-postPage', params: {slug: slugRegulations}})">
                        {{ $t('project.layout.footer.menu.info.regulations') }}
                      </nuxt-link>
                    </li>
                  </template>
                </template>
                <template v-if="currentLang === 'fr'">
                  <li>
                    <nuxt-link :to="localePath('static-montage')">
                      {{ $t('project.layout.footer.menu.info.instruction') }}
                    </nuxt-link>
                  </li>
                  <li>
                    <nuxt-link :to="localePath({name: 'cms-postPage', params: {slug: 'foire-aux-questions-faq'}})">
                      {{ $t('project.layout.footer.menu.info.faq') }}
                    </nuxt-link>
                  </li>
                  <li>
                    <nuxt-link :to="localePath({name: 'cms-postPage', params: {slug: '2342345325345'}})">
                      {{ $t('project.layout.footer.menu.info.imprint') }}
                    </nuxt-link>
                  </li>
                  <template v-if="project === 'schuette'">
                    <li v-if="slugRegulations">
                      <nuxt-link :to="localePath({name: 'cms-postPage', params: {slug: slugRegulations}})">
                        Conditions d'utilisation
                      </nuxt-link>
                    </li>
                    <li v-if="slugRegulations">
                      <nuxt-link :to="localePath({name: 'cms-postPage', params: {slug: '3254345435'}})">
                        Protection des données
                      </nuxt-link>
                    </li>
                    <li>
                      <nuxt-link :to="localePath({name: 'cms-postPage', params: {slug: '53412645'}})">
                        Droit de rétractation
                      </nuxt-link>
                    </li>
                  </template>
                  <template v-else>
                    <li v-if="slugRegulations">
                      <nuxt-link :to="localePath({name: 'cms-postPage', params: {slug: slugRegulations}})">
                        {{ $t('project.layout.footer.menu.info.regulations') }}
                      </nuxt-link>
                    </li>
                  </template>
                </template>
                <template v-if="currentLang === 'de'">
                  <li>
                    <nuxt-link :to="localePath('static-montage')">
                      {{ $t('project.layout.footer.menu.info.instruction') }}
                    </nuxt-link>
                  </li>
                  <li>
                    <nuxt-link :to="localePath({name: 'cms-postPage', params: {slug: 'faq'}})">
                      {{ $t('project.layout.footer.menu.info.faq') }}
                    </nuxt-link>
                  </li>
                  <li>
                    <nuxt-link :to="localePath({name: 'cms-postPage', params: {slug: 'impressum'}})">
                      {{ $t('project.layout.footer.menu.info.imprint') }}
                    </nuxt-link>
                  </li>
                  <template v-if="project === 'schuette'">
                    <li v-if="slugRegulations">
                      <nuxt-link :to="localePath({name: 'cms-postPage', params: {slug: slugRegulations}})">
                        Allgemeine Geschäftsbedingungen
                      </nuxt-link>
                    </li>
                    <li v-if="slugRegulations">
                      <nuxt-link :to="localePath({name: 'cms-postPage', params: {slug: '34211'}})">
                        Datenschutzerklärung
                      </nuxt-link>
                    </li>
                    <li>
                      <nuxt-link :to="localePath({name: 'cms-postPage', params: {slug: '435222'}})">
                        Widerrufsbelehrung
                      </nuxt-link>
                    </li>
                  </template>
                  <template v-else>
                    <li v-if="slugRegulations">
                      <nuxt-link :to="localePath({name: 'cms-postPage', params: {slug: slugRegulations}})">
                        {{ $t('project.layout.footer.menu.info.regulations') }}
                      </nuxt-link>
                    </li>
                  </template>
                </template>
                <template v-if="currentLang === 'en'">
                  <li>
                    <nuxt-link :to="localePath('static-montage')">
                      {{ $t('project.layout.footer.menu.info.instruction') }}
                    </nuxt-link>
                  </li>
                  <li>
                    <nuxt-link :to="localePath({name: 'cms-postPage', params: {slug: 'frequently-asked-questions-faq'}})">
                      {{ $t('project.layout.footer.menu.info.faq') }}
                    </nuxt-link>
                  </li>
                  <li>
                    <nuxt-link :to="localePath({name: 'cms-postPage', params: {slug: 'imprint'}})">
                      {{ $t('project.layout.footer.menu.info.imprint') }}
                    </nuxt-link>
                  </li>
                  <template v-if="project === 'schuette'">
                    <li v-if="slugRegulations">
                      <nuxt-link :to="localePath({name: 'cms-postPage', params: {slug: slugRegulations}})">
                        Regulations
                      </nuxt-link>
                    </li>
                    <li v-if="slugRegulations">
                      <nuxt-link :to="localePath({name: 'cms-postPage', params: {slug: '45234'}})">
                        Privacy of policy
                      </nuxt-link>
                    </li>
                    <li>
                      <nuxt-link :to="localePath({name: 'cms-postPage', params: {slug: '5645354'}})">
                        Right of withdrawal
                      </nuxt-link>
                    </li>
                  </template>
                  <template v-else>
                    <li v-if="slugRegulations">
                      <nuxt-link :to="localePath({name: 'cms-postPage', params: {slug: slugRegulations}})">
                        {{ $t('project.layout.footer.menu.info.regulations') }}
                      </nuxt-link>
                    </li>
                  </template>
                </template>
                <template v-if="currentLang === 'pl'">
                  <li>
                    <nuxt-link :to="localePath('static-montage')">
                      {{ $t('project.layout.footer.menu.info.instruction') }}
                    </nuxt-link>
                  </li>
                  <li>
                    <nuxt-link :to="localePath({name: 'cms-postPage', params: {slug: 'faq'}})">
                      {{ $t('project.layout.footer.menu.info.faq') }}
                    </nuxt-link>
                  </li>
                  <li>
                    <nuxt-link :to="localePath({name: 'cms-postPage', params: {slug: 'dane-kontaktowe'}})">
                      {{ $t('project.layout.footer.menu.info.imprint') }}
                    </nuxt-link>
                  </li>
                  <li v-if="slugRegulations">
                    <nuxt-link :to="localePath({name: 'cms-postPage', params: {slug: slugRegulations}})">
                      {{ $t('project.layout.footer.menu.info.regulations') }}
                    </nuxt-link>
                  </li>
                </template>
                <li>
                  <nuxt-link :to="localePath({name: 'cms-blog'})">
                    {{ $t('fields.blog') }}
                  </nuxt-link>
                </li>
              </ul>
            </div>

            <div class="column footer-menu is-narrow">
              <h5>{{ $t('project.layout.footer.menu.contact.title') }}</h5>
              <ul>
                <li>
                  <nuxt-link :to="user && check('seller') ? localePath('admin-messenger') : localePath('messenger')">
                    {{ $t('project.layout.footer.menu.contact.contactMessenger') }}
                  </nuxt-link>
                </li>
                <li>
                  <a :href="'mailto:' + contactEmail">{{ $t('project.layout.footer.menu.contact.contactEmail') }}</a>
                </li>
                <template v-if="project === 'schuette'">
                  <li>
                    <nuxt-link :to="localePath('b2b')">
                      {{ $t('project.layout.footer.menu.contact.b2b') }}
                    </nuxt-link>
                  </li>
                </template>
              </ul>
            </div>

            <div class="column footer-menu is-narrow">
              <h5>{{ $t('project.layout.footer.menu.social.title') }}</h5>
              <div class="social">
                <template v-if="project === 'schuette'">
                  <a href="https://www.facebook.com/schuette.shop/" target="_blank" class="footer-link">
                    <b-icon icon="facebook" size="is-medium" />
                  </a>
                  <a href="https://www.instagram.com/schuette.shop/" target="_blank" class="footer-link">
                    <b-icon icon="instagram" size="is-medium" />
                  </a>
                  <a href="https://www.youtube.com/channel/UCucQv2Mpo0dcic4v6UP7BgA?fbclid=IwAR2DxtpH5gP2FSENK3smCe46DambouUPW6p-ZGTf-uNEGCfaRMfvviWyvSI" target="_blank" class="footer-link">
                    <b-icon icon="youtube" size="is-medium" />
                  </a>
                </template>
                <template v-if="project === 'rzaska'">
                  <a href="https://www.facebook.com/Rz%C4%85ska-616267142357118/" target="_blank" class="footer-link">
                    <b-icon icon="facebook" size="is-medium" />
                  </a>
                  <a href="https://www.instagram.com/rzaska.pl/" target="_blank" class="footer-link">
                    <b-icon icon="instagram" size="is-medium" />
                  </a>
                  <a href="https://www.youtube.com/channel/UCr2jocM7Qyy-yCzVmF1qTRQ/" target="_blank" class="footer-link">
                    <b-icon icon="youtube" size="is-medium" />
                  </a>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-narrow">
          <div class="columns">
            <div class="column footer-copyright">
              <template v-if="project === 'schuette'">
                © 2019 Schuette. Powered by Fury.
              </template>
              <template v-if="project === 'rzaska'">
                © 2019 Rzaska. Powered by Fury.
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>

export default {
  data () {
    return {
      contactEmail: process.env.contactEmail,
      isCheckoutPage: null
    }
  },
  watch: {
    $route () {
      this.checkPage()
    }
  },
  created () {
    this.checkPage()
  },
  methods: {
    check (key) {
      if (this.user) {
        let availableRoles = []
        switch (key) {
          case 'seller':
            availableRoles = ['ROLE_SELLER_OWNER', 'ROLE_SELLER']
            break
          case 'user':
            availableRoles = ['ROLE_SUPER_ADMIN', 'ROLE_USER']
            break
        }

        const check = availableRoles.filter(val =>
          this.user.roles.includes(val)
        )

        return check.length > 0
      } else {
        return false
      }
    },
    checkPage () {
      const name = this.$route.name.split('___')
      if (name && name[0]) {
        this.isCheckoutPage = name[0] === 'checkout'
      }
    }
  }
}
</script>
