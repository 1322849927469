export const coreState = () => ({
  cart: null,
  changedCart: 0,
  isOpenedCart: false,
  loadingChangeCart: false,
  loadingCart: false,
  isMenuOpen: false,
  locale: null,
  productScroll: false
})

export const coreMutations = {
  updateCart (store, data) {
    store.cart = data
  },
  changedCart (store) {
    store.changedCart++
  },
  isOpenedCart (store, data) {
    store.isOpenedCart = data
  },
  isOpenedCartToggle (store) {
    store.isOpenedCart = !store.isOpenedCart
  },
  loadingCart (store, data) {
    store.loadingCart = data
  },
  loadingChangeCart (store, data) {
    if (data) {
      store.loadingChangeCart++
    } else {
      store.loadingChangeCart--
    }
  },
  isMenuOpen (store, data) {
    store.isMenuOpen = data
  },
  locale (store, data) {
    store.locale = data
  },
  productScroll (store, data) {
    store.productScroll = data
  }
}

export const coreActions = {
  updateCart ({ commit }, data) {
    commit('updateCart', data)
  },
  changedCart ({ commit }) {
    commit('changedCart')
  },
  isOpenedCart ({ commit }, data) {
    commit('isOpenedCart', data)
  },
  isOpenedCartToggle ({ commit }) {
    commit('isOpenedCartToggle')
  },
  loadingCart ({ commit }, data) {
    commit('loadingCart', data)
  },
  loadingChangeCart ({ commit }, data) {
    commit('loadingChangeCart', data)
  },
  isMenuOpen ({ commit }, data) {
    commit('isMenuOpen', data)
  },
  locale ({ commit }, data) {
    commit('locale', data)
  },
  productScroll ({ commit }, data) {
    commit('productScroll', data)
  }
}
