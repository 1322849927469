<template>
  <div>
    <nuxt />
    <global-layout hide-cart-trigger />
  </div>
</template>

<script>
import GlobalLayout from 'marketplace-front-core/components/layout/GlobalLayout'

export default {
  components: { GlobalLayout },
  data () {
    return {
      mobileMenuVisible: false,
      defaultLocale: process.env.lang
    }
  },
  head () {
    return {
      meta: [
        { name: 'robots', content: 'noindex, nofollow' }
      ],
      htmlAttrs: {
        class: 'html-admin'
      }
    }
  }
}
</script>
