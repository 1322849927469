<template>
  <div v-if="show && !isOpenedCart" class="scrollToTop" @click="scrollToTop">
    <b-icon icon="chevron-up" />
  </div>
</template>

<script>
import $ from 'jquery'
export default {
  data () {
    return {
      show: false
    }
  },
  computed: {
    isOpenedCart () {
      return this.$store.state.page.isOpenedCart
    }
  },
  mounted () {
    if (process.browser) {
      window.addEventListener('scroll', this.handleScroll)
    }
  },
  destroyed () {
    if (process.browser) {
      window.removeEventListener('scroll', this.handleScroll)
    }
  },
  methods: {
    scrollToTop () {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    },
    handleScroll (e) {
      if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        this.show = true
      } else {
        this.show = false
      }

      // Ukrywanie przycisku na stronie kasy poniżej podsumowania

      let element = document.getElementById('checkout-order-summary')
      if (element) {
        let lastElement = null
        let top = 0

        while (element !== null && element.tagName !== 'HTML') {
          if (!lastElement || element === lastElement.offsetParent) {
            top += element.offsetTop
          }

          lastElement = element
          element = element.parentElement
        }

        if ((window.scrollY + $(window).height()) > top) {
          this.show = false
        }
      }
    }
  }
}
</script>
