<template>
  <div class="product-detail">
    <b-field
      :type="{ 'is-danger': errors.has('clientNote') }"
      :message="errors.first('clientNote')"
      class="w-100"
    >
      <b-input
        v-model="clientNote"
        v-validate="'max:31'"
        name="clientNote"
        autocomplete="off"
        :placeholder="$t('cart.clientNote')"
        class="client-note"
        :class="{'empty': !clientNote}"
        @input="save"
      />
    </b-field>
  </div>
</template>

<script>
import debounce from 'marketplace-front-core/utils/debounce'

export default {
  props: {
    orderIndex: {
      type: Number,
      required: true
    },
    productIndex: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      clientNote: ''
    }
  },
  computed: {
    getProduct () {
      const cart = this.$store.state.page.cart
      return cart && cart.orders && cart.orders[this.orderIndex].products[this.productIndex]
        ? cart && cart.orders && cart.orders[this.orderIndex].products[this.productIndex]
        : { clientNote: '', orderOfferProduct: null }
    }
  },
  watch: {
    'getProduct.clientNote': {
      handler (value) {
        this.clientNote = value
      },
      immediate: true
    }
  },
  methods: {
    save: debounce(function () {
      this.$api.cart.clientNote(this.getProduct.orderOfferProductId, this.clientNote)
        .then((res) => {
          const cart = this._clone(this.$store.state.page.cart)
          if (cart && cart.orders && cart.orders[this.orderIndex].products[this.productIndex]) {
            cart.orders[this.orderIndex].products[this.productIndex].clientNote = this.clientNote
            this.$store.dispatch('page/updateCart', cart)
          }
        })
        .catch(() => {
          this.$buefy.toast.open({
            message: 'Error',
            type: 'is-warning'
          })
        })
    }, 500)
  }
}
</script>
