import { render, staticRenderFns } from "./MenuCart.vue?vue&type=template&id=4ea89760&"
import script from "./MenuCart.vue?vue&type=script&lang=js&"
export * from "./MenuCart.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../b_front/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports