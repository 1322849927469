<script>
import debounce from 'marketplace-front-core/utils/debounce'

export default {
  data () {
    return {
      refreshQuantity: 0
    }
  },
  computed: {
    loadingCart () {
      return this.$store.state.page.loadingCart
    },
    loadingChangeCart () {
      return this.$store.state.page.loadingChangeCart > 0
    }
  },
  methods: {
    cartDeleteProduct (id, product) {
      this.eventRemoveProductFromCart(product)
      this.$store.dispatch('page/loadingChangeCart', true)
      this.$api.cart.deleteProduct(id)
        .then((res) => {
          this.$store.dispatch('page/updateCart', res)
          this.$store.dispatch('page/changedCart')

          this.$buefy.toast.open({
            message: this.$t('cart.successDelete'),
            type: 'is-success'
          })
        })
        .catch(() => {
          this.$buefy.toast.open({
            message: this.$t('cart.errorDelete'),
            type: 'is-danger'
          })
        })
        .finally(() => {
          this.$store.dispatch('page/loadingChangeCart', false)
        })
    },
    cartChangeProductQuantity: debounce(function (quantity, orderOfferProductId) {
      this.$store.dispatch('page/loadingChangeCart', true)
      this.$api.cart.update(orderOfferProductId, { quantity })
        .then((res) => {
          if (res.error) {
            this.refreshQuantity++
            if (res.error.message === 'NOT_ENOUGH_QUANTITY') {
              this.$buefy.toast.open({
                message: this.$t('cart.errorQuantity'),
                type: 'is-warning'
              })
            } else {
              this.$buefy.toast.open({
                message: this.$t('cart.errorUpdate'),
                type: 'is-danger'
              })
            }
          } else {
            this.refreshQuantity++
            this.$store.dispatch('page/updateCart', res)
            this.$store.dispatch('page/changedCart')
          }
        })
        .catch(() => {
          this.$buefy.toast.open({
            message: this.$t('cart.errorUpdate'),
            type: 'is-danger'
          })
        })
        .finally(() => {
          this.$store.dispatch('page/loadingChangeCart', false)
        })
    }, 1000)
  }
}
</script>
