<template>
  <div class="error">
    <b-notification type="is-danger" :closable="false">
      <div class="columns is-mobile is-vcentered">
        <div class="column is-narrow">
          <b-icon size="is-large" icon="alert-circle" />
        </div>
        <div class="column">
          <p v-if="error.statusCode" class="title is-marginless">
            {{ error.statusCode }}
          </p>
          <p v-if="error.message">
            {{ error.message }}
          </p>
        </div>
      </div>
    </b-notification>
  </div>
</template>

<script>
export default {
  props: {
    error: {
      type: Object,
      default: null
    }
  },
  head () {
    return { title: 'Error' }
  },
  layout: 'default'
}
</script>
