import { render, staticRenderFns } from "./PaymentsIcons.vue?vue&type=template&id=7969d1a4&scoped=true&"
import script from "./PaymentsIcons.vue?vue&type=script&lang=js&"
export * from "./PaymentsIcons.vue?vue&type=script&lang=js&"
import style0 from "./PaymentsIcons.vue?vue&type=style&index=0&id=7969d1a4&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../b_front/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7969d1a4",
  null
  
)

export default component.exports