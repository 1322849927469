<template>
  <header>
    <div class="container main-container">
      <menu-top />
      <div v-if="!(isCheckoutPage || hideMenu)" :class="{'is-hidden-touch': !isHomePage}">
        <template v-if="project === 'rzaska'">
          <product-tiles />
        </template>
        <template v-if="project === 'schuette'">
          <category-tiles class="is-hidden-touch" />
        </template>
      </div>
    </div>
    <b-modal
      v-model="$store.state.page.isMenuOpen"
      full-screen
      has-modal-card
      animation="fade"
      scroll="keep"
      :can-cancel="false"
      class="is-hidden-desktop"
    >
      <menu-mobile />
    </b-modal>
    <side-cart />
  </header>
</template>

<script>
import SideCart from 'marketplace-front-core/components/shared/SideCart'
import ProductTiles from './ProductTiles'
import CategoryTiles from './CategoryTiles'
import MenuTop from './Menu'
import MenuMobile from './MenuMobile'

export default {
  components: {
    SideCart,
    ProductTiles,
    MenuTop,
    MenuMobile,
    CategoryTiles
  },
  props: {
    hideMenu: {
      type: Boolean
    }
  },
  data () {
    return {
      isHomePage: null,
      isCheckoutPage: null
    }
  },
  watch: {
    $route () {
      this.checkPage()
    }
  },
  errorCaptured (err, vm, info) {
    console.log(`error: ${err.toString()} \ninfo: ${info}`)
    return false
  },
  created () {
    this.checkPage()
  },
  methods: {
    checkPage () {
      const name = this.$route.name.split('___')
      if (name && name[0]) {
        this.isHomePage = name[0] === 'index'
        this.isCheckoutPage = name[0] === 'checkout'
      }
    }
  }
}
</script>
