<template>
  <div class="category-tile">
    <div class="category-tile-content">
      <div class="category-tile-icon">
        <slot name="icon" />
      </div>
      <div class="category-tile-name-container">
        <div class="category-tile-name">
          {{ name }}
        </div>
        <rating-average :average="ratingAverage" />
        <!-- <rating-average :average="ratingAverage" :total="ratingTotal" /> -->
      </div>
    </div>
    <category-arrow class="category-arrow" />
  </div>
</template>

<script>
import RatingAverage from './RatingAverage'
import CategoryArrow from '~/assets/images/category/category-arrow.svg?inline'

export default {
  name: 'CategoryTile',
  components: { CategoryArrow, RatingAverage },
  props: {
    name: {
      type: String,
      default: ''
    },
    ratingAverage: {
      type: Number,
      default: 5
    },
    ratingTotal: {
      type: Number,
      default: 0
    }
  }
}
</script>

<style lang="scss" scoped>
$category-tile-bg-color: #F7F7F7;
$category-tile-hover-bg-color: #EEEEEE;
.category-tile {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0;
  width: 100%;
  height: 96px;
  padding: 0 16px;
  background-color: $category-tile-bg-color;
  border-radius: 8px;
  transition: gap 0.3s ease-in-out;
  &:hover {
    cursor: pointer;
    gap: 12px;
    // background-color: $category-tile-hover-bg-color;
    // box-shadow: 0 0 6px rgba(black, 0.36);
    .category-arrow {
      width: 16px;
      visibility: visible;
      opacity: 1;
    }
  }
  &:focus-within {
    // border: 1px solid #dddddd;
    box-shadow: 0 6px 12px #dddddd;
  }
  @include touch {
    height: 72px;
    justify-content: space-between;
    .category-tile-content {
      gap: 12px !important;
      .category-tile-icon {
        height: 40px !important;
        svg {
          width: 40px !important;
          height: 40px !important;
        }
      }
    }
  }
  .category-tile-content {
    display: flex;
    align-items: center;
    gap: 16px;
    .category-tile-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 44px;
      height: 44px;
      svg {
        width: 44px;
        height: 44px;
      }
      .video {
        width: 44px;
        height: 44px;
        :deep(video) {
          height: 44px !important;
        }
      }
    }
    .category-tile-name {
      font-size: 16px;
      font-weight: 600;
      @include touch {
        line-height: 1.3;
      }
    }
  }
  .category-arrow {
    flex-shrink: 0;
    width: 16px;
    height: 14px;
    transition: all 0.3s ease-in-out;
    @include desktop {
      width: 0;
      visibility: hidden;
      opacity: 0;
    }
  }
}
</style>
