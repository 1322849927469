<template>
  <footer class="new-footer" :class="[{'checkout-footer': isCheckout}]">
    <div class="container-1000">
      <div class="footer-header">
        <nuxt-link :to="localePath('index')" aria-label="schuette">
          <logo-schuette-white />
        </nuxt-link>

        <!-- <payments-icons v-if="!noPayment" class="checkout-payments is-flex-justify-center" /> -->

        <div class="footer-socials">
          <a href="https://www.facebook.com/schuette.shop/" alt="facebook" aria-label="facebook" target="_blank">
            <logo-facebook />
          </a>
          <a href="https://www.instagram.com/schuette.shop/" alt="instagram" aria-label="instagram" target="_blank">
            <logo-instagram />
          </a>
          <a href="https://www.youtube.com/channel/UCucQv2Mpo0dcic4v6UP7BgA?fbclid=IwAR2DxtpH5gP2FSENK3smCe46DambouUPW6p-ZGTf-uNEGCfaRMfvviWyvSI" alt="youtube" aria-label="youtube" target="_blank">
            <logo-youtube />
          </a>
        </div>
      </div>
      <div class="footer-sections">
        <div class="footer-section">
          <nuxt-link :to="localePath('categories-pleated-blinds')" class="product-tile">
            <div class="footer-section-title">
              {{ $t('project.layout.footer.menu.pb') }} <arrow-right class="footer-arrow" />
            </div>
          </nuxt-link>
          <ul>
            <li>
              <nuxt-link :to="localePath({ name: 'product', params: {productName: products.pbh[currentLang].productName, productId: products.pbh[currentLang].productId} })" class="product-tile">
                <span v-html="products.pbh[currentLang].name" />
              </nuxt-link>
            </li>
            <li>
              <nuxt-link :to="localePath({ name: 'product', params: {productName: products.pbwd[currentLang].productName, productId: products.pbwd[currentLang].productId} })" class="product-tile">
                <span v-html="products.pbwd[currentLang].name" />
              </nuxt-link>
            </li>
            <li>
              <nuxt-link :to="localePath({ name: 'product', params: {productName: products.pbd[currentLang].productName, productId: products.pbd[currentLang].productId} })" class="product-tile">
                <span v-html="products.pbd[currentLang].name" />
              </nuxt-link>
            </li>
            <li>
              <nuxt-link :to="localePath({ name: 'product', params: {productName: products.psrb[currentLang].productName, productId: products.psrb[currentLang].productId} })" class="product-tile">
                <span v-html="products.psrb[currentLang].name" />
              </nuxt-link>
            </li>
          </ul>
          <nuxt-link :to="localePath('categories-roller-blinds')" class="product-tile">
            <div class="footer-section-title">
              {{ $t('project.layout.footer.menu.rb') }} <arrow-right class="footer-arrow" />
            </div>
          </nuxt-link>
          <ul>
            <li>
              <nuxt-link :to="localePath({ name: 'product', params: { productName: products.dnrb[currentLang].productName, productId: products.dnrb[currentLang].productId } })" class="product-tile">
                <span v-html="products.dnrb[currentLang].name" />
              </nuxt-link>
            </li>
            <li>
              <nuxt-link :to="localePath({ name: 'product', params: { productName: products.tbrb[currentLang].productName, productId: products.tbrb[currentLang].productId } })" class="product-tile">
                <span v-html="products.tbrb[currentLang].name" />
              </nuxt-link>
            </li>
          </ul>
          <nuxt-link :to="localePath('categories-curtains')" class="product-tile">
            <div class="footer-section-title">
              {{ $t('project.layout.footer.menu.ct') }} <arrow-right class="footer-arrow" />
            </div>
          </nuxt-link>
          <ul>
            <li>
              <nuxt-link :to="localePath({ name: 'product', params: {productName: products.cbc[currentLang].productName, productId: products.cbc[currentLang].productId} })" class="product-tile">
                <span v-html="products.cbc[currentLang].name" />
              </nuxt-link>
            </li>
            <li>
              <nuxt-link :to="localePath({ name: 'product', params: {productName: products.mvc[currentLang].productName, productId: products.mvc[currentLang].productId} })" class="product-tile">
                <span v-html="products.mvc[currentLang].name" />
              </nuxt-link>
            </li>
          </ul>
          <nuxt-link :to="localePath({ name: 'product', params: { productName: products.r[currentLang].productName, productId: products.r[currentLang].productId } })" class="product-tile">
            <div class="footer-section-title">
              {{ $t('project.layout.footer.menu.sl') }} <arrow-right class="footer-arrow" />
            </div>
          </nuxt-link>
          <ul>
            <li>
              <nuxt-link :to="localePath({ name: 'product', params: {productName: products.r[currentLang].productName, productId: products.r[currentLang].productId} })" class="product-tile">
                <span v-html="products.r[currentLang].name" />
              </nuxt-link>
            </li>
            <li>
              <nuxt-link :to="localePath({ name: 'product', params: {productName: products.sb[currentLang].productName, productId: products.sb[currentLang].productId} })" class="product-tile">
                <span v-html="products.sb[currentLang].name" />
              </nuxt-link>
            </li>
          </ul>
        </div>
        <div class="footer-section">
          <div class="footer-section-title">
            {{ $t('project.layout.footer.information') }}
          </div>
          <ul>
            <li>
              <nuxt-link :to="localePath('static-montage')">
                {{ $t('project.layout.footer.menu.info.instruction') }}
              </nuxt-link>
            </li>
            <li>
              <nuxt-link :to="localePath({name: 'cms-postPage', params: informations[currentLang].faq})">
                {{ $t('project.layout.footer.menu.info.faq') }}
              </nuxt-link>
            </li>
            <li>
              <nuxt-link :to="localePath({name: 'cms-postPage', params: informations[currentLang].imprint})">
                {{ $t('project.layout.footer.menu.info.imprint') }}
              </nuxt-link>
            </li>
            <li v-if="slugRegulations">
              <nuxt-link :to="localePath({name: 'cms-postPage', params: {slug: slugRegulations}})">
                {{ $t('project.layout.footer.menu.info.regulations') }}
              </nuxt-link>
            </li>
            <li v-if="slugRegulations">
              <nuxt-link :to="localePath({name: 'cms-postPage', params: informations[currentLang].privacy})">
                {{ $t('project.layout.footer.menu.info.privacy') }}
              </nuxt-link>
            </li>
            <li>
              <nuxt-link :to="localePath({name: 'cms-postPage', params: informations[currentLang].withdrawal})">
                {{ $t('project.layout.footer.menu.info.withdrawal') }}
              </nuxt-link>
            </li>
            <li>
              <nuxt-link :to="localePath({name: 'cms-blog'})">
                {{ $t('fields.blog') }}
              </nuxt-link>
            </li>
          </ul>
        </div>
        <div class="footer-section-container">
          <div class="footer-section">
            <div class="footer-section-title">
              {{ $t('project.layout.footer.customerCare') }}
            </div>
            <ul>
              <li>
                <nuxt-link :to="user && check('seller') ? localePath('admin-messenger') : localePath('messenger')">
                  {{ $t('project.layout.footer.menu.contact.contactMessenger') }}
                </nuxt-link>
              </li>
              <li>
                <a :href="'mailto:' + contactEmail">{{ $t('project.layout.footer.menu.contact.contactEmail') }}</a>
              </li>
            </ul>
          </div>
          <div class="footer-section">
            <div class="footer-section-title">
              {{ $t('project.layout.footer.professionals') }}
            </div>
            <ul>
              <li>
                <nuxt-link :to="localePath('b2b')">
                  {{ $t('project.layout.footer.menu.contact.b2b') }}
                </nuxt-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="footer-footer">
        <p>
          © 2023 Schuette. Powered by Fury.
        </p>
        <footer-star />
      </div>
    </div>
  </footer>
</template>

<script>
// import PaymentsIcons from 'marketplace-front-core/components/shared/PaymentsIcons'
import ArrowRight from 'marketplace-front-core/assets/generate-icons/icons/arrow-right.svg?inline'
import LogoSchuetteWhite from '~/static/images/logo-schuette-white.svg?inline'
import LogoFacebook from '~/static/images/facebook-square.svg?inline'
import LogoInstagram from '~/static/images/instagram-square.svg?inline'
import LogoYoutube from '~/static/images/youtube-square.svg?inline'
import FooterStar from '~/static/images/footer-star.svg?inline'

export default {
  name: 'FooterSchuette',
  components: {
    // PaymentsIcons,
    LogoSchuetteWhite,
    LogoFacebook,
    LogoInstagram,
    LogoYoutube,
    FooterStar,
    ArrowRight
  },
  props: {
    noPayment: {
      type: Boolean
    }
  },
  data () {
    return {
      contactEmail: process.env.contactEmail,
      informations: {
        en: {
          privacy: { slug: '45234' },
          withdrawal: { slug: '5645354' },
          faq: { slug: 'frequently-asked-questions-faq' },
          imprint: { slug: 'imprint' }
        },
        de: {
          privacy: { slug: '34211' },
          withdrawal: { slug: '435222' },
          faq: { slug: 'faq' },
          imprint: { slug: 'impressum' }
        },
        fr: {
          privacy: { slug: '3254345435' },
          withdrawal: { slug: '53412645' },
          faq: { slug: 'foire-aux-questions-faq' },
          imprint: { slug: '2342345325345' }
        },
        es: {
          privacy: { slug: '5458752360' },
          withdrawal: { slug: '7455455' },
          faq: { slug: 'preguntas-frecuentes-faq' },
          imprint: { slug: '25454554' }
        },
        it: {
          privacy: { slug: '789032' },
          withdrawal: { slug: '267843' },
          faq: { slug: 'domande-frequenti' },
          imprint: { slug: '2341255333' }
        },
        pl: {
          privacy: { slug: '45234' },
          withdrawal: { slug: '5645354' },
          faq: { slug: 'frequently-asked-questions-faq' },
          imprint: { slug: 'imprint' }
        },
        nl: {
          privacy: { slug: '45234' },
          withdrawal: { slug: '5645354' },
          faq: { slug: 'frequently-asked-questions-faq' },
          imprint: { slug: 'imprint' }
        },
        no: {
          privacy: { slug: '45234' },
          withdrawal: { slug: '5645354' },
          faq: { slug: 'frequently-asked-questions-faq' },
          imprint: { slug: 'imprint' }
        }
      },
      products: {
        // Pleated Blinds
        pbh: {
          en: {
            name: 'Honeycomb Blind',
            productName: 'schuette-wabenplissee-ohne-bohren-nach-mas-suprafix-klemmhalter-incognito-standard-honey-kollektion',
            productId: 10948
          },
          de: {
            name: '<b>Wabenplissee</b>',
            productName: 'schuette-wabenplissee-ohne-bohren-nach-mas-suprafix-klemmhalter-incognito-standard-honey-kollektion',
            productId: 10948
          },
          fr: {
            name: 'Store plissé nid da\'beille',
            productName: 'schuette-wabenplissee-ohne-bohren-nach-mas-suprafix-klemmhalter-incognito-standard-honey-kollektion',
            productId: 10948
          },
          es: {
            name: 'Estor plisado alveolar',
            productName: 'schuette-wabenplissee-ohne-bohren-nach-mas-suprafix-klemmhalter-incognito-standard-honey-kollektion',
            productId: 10948
          },
          it: {
            name: 'Honeycomb Blind',
            productName: 'schuette-wabenplissee-ohne-bohren-nach-mas-suprafix-klemmhalter-incognito-standard-honey-kollektion',
            productId: 10948
          },
          pl: {
            name: 'Honeycomb Blind',
            productName: 'schuette-wabenplissee-ohne-bohren-nach-mas-suprafix-klemmhalter-incognito-standard-honey-kollektion',
            productId: 10948
          },
          nl: {
            name: 'Honeycomb Blind',
            productName: 'schuette-wabenplissee-ohne-bohren-nach-mas-suprafix-klemmhalter-incognito-standard-honey-kollektion',
            productId: 10948
          },
          no: {
            name: 'Plissegardin med bikakestruktur',
            productName: 'schuette-wabenplissee-ohne-bohren-nach-mas-suprafix-klemmhalter-incognito-standard-honey-kollektion',
            productId: 10948
          }
        },
        pbwd: {
          en: {
            name: 'Pleated Blind without drilling',
            productName: 'schuette-pleated-blind-made-to-measure-without-drilling-suprafix-clamp-holder-incognito-standard',
            productId: 10
          },
          de: {
            name: 'Plissee ohne Bohren',
            productName: 'schuette-plissee-ohne-bohren-nach-mas-suprafix-klemmhalter-incognito-standard-premium-kollektion',
            productId: 10
          },
          fr: {
            name: 'Store plissé sans perçage',
            productName: 'schuette-stores-plisses-sur-mesure-sans-percage-suprafix-fixation-incognito-standard-a-pincer',
            productId: 10
          },
          es: {
            name: 'Estor plisado sin taladrar',
            productName: 'estores-plisados-schuette-hechos-a-medida-sin-taladrar-soporte-de-sujecion-suprafix-estandar-incognito',
            productId: 10
          },
          it: {
            name: 'Pleated Blind without drilling',
            productName: 'schuette-plissee-ohne-bohren-nach-mas-suprafix-klemmhalter-incognito-standard-premium-kollektion',
            productId: 10
          },
          pl: {
            name: 'Pleated Blind without drilling',
            productName: 'schuette-plissee-ohne-bohren-nach-mas-suprafix-klemmhalter-incognito-standard-premium-kollektion',
            productId: 10
          },
          nl: {
            name: 'Pleated Blind without drilling',
            productName: 'schuette-pleated-blind-made-to-measure-without-drilling-suprafix-clamp-holder-incognito-standard',
            productId: 10
          },
          no: {
            name: 'Plissegardin uten boring',
            productName: 'schuette-pleated-blind-made-to-measure-without-drilling-suprafix-clamp-holder-incognito-standard',
            productId: 10
          }
        },
        pbd: {
          en: {
            name: 'Pleated Blind with drilling',
            productName: 'schuette-pleated-blind-made-to-measure-with-drilling-suprafix-mounts-for-screwing-premium-collection',
            productId: 112
          },
          de: {
            name: 'Plissee mit Bohren',
            productName: 'schuette-plissee-mit-bohren-nach-mas-suprafix-halterungen-mit-bohren-premium-kollektion-white',
            productId: 112
          },
          fr: {
            name: 'Store plissé avec perçage',
            productName: 'schuette-stores-plisses-sur-mesure-suprafix-fixation-avec-percage-a-visser-collection-premium',
            productId: 112
          },
          es: {
            name: 'Estor plisado para taladrar',
            productName: 'estores-plisados-schuette-hechos-a-medida-para-atornillar-soporte-de-fijacion-suprafix-para',
            productId: 112
          },
          it: {
            name: 'Pleated Blind with drilling',
            productName: 'schuette-pleated-blind-made-to-measure-with-drilling-suprafix-mounts-for-screwing-premium-collection',
            productId: 112
          },
          pl: {
            name: 'Pleated Blind with drilling',
            productName: 'schuette-pleated-blind-made-to-measure-with-drilling-suprafix-mounts-for-screwing-premium-collection',
            productId: 112
          },
          nl: {
            name: 'Pleated Blind with drilling',
            productName: 'schuette-pleated-blind-made-to-measure-with-drilling-suprafix-mounts-for-screwing-premium-collection',
            productId: 112
          },
          no: {
            name: 'Plissegardin med boring',
            productName: 'schuette-pleated-blind-made-to-measure-with-drilling-suprafix-mounts-for-screwing-premium-collection',
            productId: 112
          }
        },
        psrb: {
          en: {
            name: 'Pleated Skylight Roof Blind',
            productName: 'schuette-pleated-skylight-roof-blind-made-to-measure-with-drilling-premium-collection-white-day-white',
            productId: 648
          },
          de: {
            name: 'Dachfenster Plissee',
            productName: 'schuette-dachfenster-plissee-nach-mas-premium-kollektion-white-day-weis-profilfarbe-weis',
            productId: 648
          },
          fr: {
            name: 'Store plissé pour fenêtre de toit',
            productName: 'schuette-stores-plisses-sur-mesure-pour-fenetre-de-toit-collection-premium-white-day-blanc-couleur',
            productId: 648
          },
          es: {
            name: 'Estor plisado para tragaluz',
            productName: 'estores-plisados-schuette-para-tragaluces-hechos-a-medida-coleccion-premium-white-day-blanco',
            productId: 648
          },
          it: {
            name: 'Pleated Skylight Roof Blind',
            productName: 'schuette-pleated-skylight-roof-blind-made-to-measure-with-drilling-premium-collection-white-day-white',
            productId: 648
          },
          pl: {
            name: 'Pleated Skylight Roof Blind',
            productName: 'schuette-pleated-skylight-roof-blind-made-to-measure-with-drilling-premium-collection-white-day-white',
            productId: 648
          },
          nl: {
            name: 'Pleated Skylight Roof Blind',
            productName: 'schuette-pleated-skylight-roof-blind-made-to-measure-with-drilling-premium-collection-white-day-white',
            productId: 648
          },
          no: {
            name: 'Plissegardin til takvindu',
            productName: 'schuette-pleated-skylight-roof-blind-made-to-measure-with-drilling-premium-collection-white-day-white',
            productId: 648
          }
        },
        // Roller Blinds
        dnrb: {
          en: {
            name: 'Day and Night Roller Blind',
            productName: 'schuette-double-roller-blind-without-drilling-with-drilling-2in1-made-to-measure-day-night-collection',
            productId: 1966
          },
          de: {
            name: 'Tag & Nacht Doppelrollo',
            productName: 'schuette-rollo-ohne-bohren-mit-bohren-2in1-nach-mas-nacht-doppelrollo-kollektion-white-snow-weis',
            productId: 1966
          },
          fr: {
            name: 'Store enrouleur jour nuit',
            productName: 'schuette-store-enrouleur-sur-mesure-sans-percage-et-avec-percage-2en1-collection-jour-nuit-white',
            productId: 1966
          },
          es: {
            name: 'Estor enrollable noche y dia',
            productName: 'estor-enrollable-schuette-sin-atornillar-y-atornillando-2in1-a-medida-coleccion-day-night-white-snow',
            productId: 1966
          },
          it: {
            name: 'Day and Night Roller Blind',
            productName: 'schuette-double-roller-blind-without-drilling-with-drilling-2in1-made-to-measure-day-night-collection',
            productId: 1966
          },
          pl: {
            name: 'Day and Night Roller Blind',
            productName: 'schuette-double-roller-blind-without-drilling-with-drilling-2in1-made-to-measure-day-night-collection',
            productId: 1966
          },
          nl: {
            name: 'Day and Night Roller Blind',
            productName: 'schuette-double-roller-blind-without-drilling-with-drilling-2in1-made-to-measure-day-night-collection',
            productId: 1966
          },
          no: {
            name: 'Rullegardin dag og natt',
            productName: 'schuette-double-roller-blind-without-drilling-with-drilling-2in1-made-to-measure-day-night-collection',
            productId: 1966
          }
        },
        tbrb: {
          en: {
            name: 'Thermo Blackout Roller Blind',
            productName: 'schuette-made-to-measure-blackout-blinds-without-drilling-with-drilling-2in1-thermo-blackout-collection',
            productId: 2147
          },
          de: {
            name: 'Thermo Blackout Verdunkelungsrollo',
            productName: 'schuette-verdunkelungsrollo-ohne-bohren-mit-bohren-2in1-nach-mas-kollektion-thermo-blackout-snowflake',
            productId: 2147
          },
          fr: {
            name: 'Store enrouleur occultant Thermo Blackout',
            productName: 'schuette-store-enrouleur-occultant-sur-mesure-sans-percage-avec-percage-2en1-collection-thermo-blackout',
            productId: 2147
          },
          es: {
            name: 'Estor enrollable opaco Thermo Blackout',
            productName: 'estor-enrollable-schuette-sin-atornillar-y-atornillando-2in1-a-medida-coleccion-thermo-blackout-snowflake',
            productId: 2147
          },
          it: {
            name: 'Thermo Blackout Roller Blind',
            productName: 'schuette-made-to-measure-blackout-blinds-without-drilling-with-drilling-2in1-thermo-blackout-collection',
            productId: 2147
          },
          pl: {
            name: 'Thermo Blackout Roller Blind',
            productName: 'schuette-made-to-measure-blackout-blinds-without-drilling-with-drilling-2in1-thermo-blackout-collection',
            productId: 2147
          },
          nl: {
            name: 'Thermo Blackout Roller Blind',
            productName: 'schuette-made-to-measure-blackout-blinds-without-drilling-with-drilling-2in1-thermo-blackout-collection',
            productId: 2147
          },
          no: {
            name: 'Rullegardin Thermo Blackout',
            productName: 'schuette-made-to-measure-blackout-blinds-without-drilling-with-drilling-2in1-thermo-blackout-collection',
            productId: 2147
          }
        },
        // Curtains
        cbc: {
          en: {
            name: 'Century Black Blackout Curtain',
            productName: 'schuette-verdunkelungs-vorhang-gardine-mit-osen-nach-mas-century-black-kollektion-pearl-white-weis',
            productId: 12275
          },
          de: {
            name: 'Century Black Verdunkelungsvorhang',
            productName: 'schuette-verdunkelungs-vorhang-gardine-mit-osen-nach-mas-century-black-kollektion-pearl-white-weis',
            productId: 12275
          },
          fr: {
            name: 'Rideau occultant Century Black',
            productName: 'schuette-verdunkelungs-vorhang-gardine-mit-osen-nach-mas-century-black-kollektion-pearl-white-weis',
            productId: 12275
          },
          es: {
            name: 'Cortina opaca Century Black',
            productName: 'schuette-verdunkelungs-vorhang-gardine-mit-osen-nach-mas-century-black-kollektion-pearl-white-weis',
            productId: 12275
          },
          it: {
            name: 'Century Black Blackout Curtain',
            productName: 'schuette-verdunkelungs-vorhang-gardine-mit-osen-nach-mas-century-black-kollektion-pearl-white-weis',
            productId: 12275
          },
          pl: {
            name: 'Century Black Blackout Curtain',
            productName: 'schuette-verdunkelungs-vorhang-gardine-mit-osen-nach-mas-century-black-kollektion-pearl-white-weis',
            productId: 12275
          },
          nl: {
            name: 'Century Black Blackout Curtain',
            productName: 'schuette-verdunkelungs-vorhang-gardine-mit-osen-nach-mas-century-black-kollektion-pearl-white-weis',
            productId: 12275
          },
          no: {
            name: 'Blendingsgardin Century Black',
            productName: 'schuette-verdunkelungs-vorhang-gardine-mit-osen-nach-mas-century-black-kollektion-pearl-white-weis',
            productId: 12275
          }
        },
        mvc: {
          en: {
            name: 'Millenium Velvet Blackout Curtain',
            productName: 'schuette-blackout-curtain-with-eyelets-millenium-velvet-collection-polar-bear-white-beige-1-piece',
            productId: 4163
          },
          de: {
            name: 'Millenium Velvet Verdunkelungsvorhan',
            productName: 'schuette-verdunkelungs-vorhang-gardine-mit-osen-nach-mas-millenium-velvet-kollektion-polar-bear-weis-grau-beige',
            productId: 4163
          },
          fr: {
            name: 'Rideau occultant Millenium Velvet',
            productName: 'schuette-rideau-occultant-rideau-avec-oillets-millenium-velvet-collection-polar-bear-blanc-beige',
            productId: 4163
          },
          es: {
            name: 'Cortina opaca Millenium Velvet',
            productName: 'schuette-cortina-blackout-con-ojales-coleccion-millenium-velvet-polar-bear-blanco-beige-1-pieza',
            productId: 4163
          },
          it: {
            name: 'Millenium Velvet Blackout Curtain',
            productName: 'schuette-blackout-curtain-with-eyelets-millenium-velvet-collection-polar-bear-white-beige-1-piece',
            productId: 4163
          },
          pl: {
            name: 'Millenium Velvet Blackout Curtain',
            productName: 'schuette-blackout-curtain-with-eyelets-millenium-velvet-collection-polar-bear-white-beige-1-piece',
            productId: 4163
          },
          nl: {
            name: 'Millenium Velvet Blackout Curtain',
            productName: 'schuette-blackout-curtain-with-eyelets-millenium-velvet-collection-polar-bear-white-beige-1-piece',
            productId: 4163
          },
          no: {
            name: 'Blendingsgardin Millenium Velvet',
            productName: 'schuette-blackout-curtain-with-eyelets-millenium-velvet-collection-polar-bear-white-beige-1-piece',
            productId: 4163
          }
        },
        // Relax
        r: {
          en: {
            name: 'Decorative velvet Pillowcase',
            productName: 'schuette-dekorativer-kissenbezug-aus-samt-mit-verdecktem-reisverschluss-millenium-velvet-kollektion',
            productId: 12703
          },
          de: {
            name: 'Dekorativer Samt-Kissenbezug',
            productName: 'schuette-dekorativer-kissenbezug-aus-samt-mit-verdecktem-reisverschluss-millenium-velvet-kollektion',
            productId: 12703
          },
          fr: {
            name: "Taie d'oreiller décorative en velours",
            productName: 'schuette-dekorativer-kissenbezug-aus-samt-mit-verdecktem-reisverschluss-millenium-velvet-kollektion',
            productId: 12703
          },
          es: {
            name: 'Funda de almohada decorativa de terciopelo',
            productName: 'schuette-dekorativer-kissenbezug-aus-samt-mit-verdecktem-reisverschluss-millenium-velvet-kollektion',
            productId: 12703
          },
          it: {
            name: 'Decorative velvet Pillowcase',
            productName: 'schuette-dekorativer-kissenbezug-aus-samt-mit-verdecktem-reisverschluss-millenium-velvet-kollektion',
            productId: 12703
          },
          pl: {
            name: 'Decorative velvet Pillowcase',
            productName: 'schuette-dekorativer-kissenbezug-aus-samt-mit-verdecktem-reisverschluss-millenium-velvet-kollektion',
            productId: 12703
          },
          nl: {
            name: 'Decorative velvet Pillowcase',
            productName: 'schuette-dekorativer-kissenbezug-aus-samt-mit-verdecktem-reisverschluss-millenium-velvet-kollektion',
            productId: 12703
          },
          no: {
            name: 'Stretchlaken',
            productName: 'schuette-dekorativer-kissenbezug-aus-samt-mit-verdecktem-reisverschluss-millenium-velvet-kollektion',
            productId: 12703
          }
        },
        // Fitted sheets
        sb: {
          en: {
            name: 'Fitted sheet',
            productName: 'schuette-fitted-sheet-with-all-round-elastic-wow-collection-white-90-x-200-x-20-cm',
            productId: 66837
          },
          de: {
            name: 'Spannbettlaken',
            productName: 'schuette-spannbettlaken-mit-rundumgummi-90-x-200-x-20-cm-wow-kollektion-weis',
            productId: 66837
          },
          fr: {
            name: 'Drap housse',
            productName: 'schuette-drap-housse-avec-elastique-circulaire-collection-wow-blanc-90-x-200-x-20-cm',
            productId: 66837
          },
          es: {
            name: 'Sábana bajera',
            productName: 'sabana-bajera-schuette-con-elastico-integral-wow-coleccion-blanco-90-x-200-x-20-cm',
            productId: 66837
          },
          it: {
            name: 'Lenzuolo con angoli',
            productName: 'lenzuolo-teso-schuette-con-elastico-integrale-wow-collezione-bianco-90-x-200-x-20-cm',
            productId: 66837
          },
          pl: {
            name: 'Fitted sheet',
            productName: 'schuette-dekorativer-kissenbezug-aus-samt-mit-verdecktem-reisverschluss-millenium-velvet-kollektion',
            productId: 66837
          },
          nl: {
            name: 'Hoeslaken',
            productName: 'schuette-fitted-sheet-with-all-round-elastic-wow-collection-white-90-x-200-x-20-cm',
            productId: 66837
          },
          no: {
            name: 'Putetrekk',
            productName: 'schuette-fitted-sheet-with-all-round-elastic-wow-collection-white-90-x-200-x-20-cm',
            productId: 66837
          }
        }
      }
    }
  },
  computed: {
    isCheckout () {
      return this.$route.name.split('___')[0] === 'checkout'
    }
  },
  created () {
    this.checkPage()
  },
  methods: {
    check (key) {
      if (this.user) {
        let availableRoles = []
        switch (key) {
          case 'seller':
            availableRoles = ['ROLE_SELLER_OWNER', 'ROLE_SELLER']
            break
          case 'user':
            availableRoles = ['ROLE_SUPER_ADMIN', 'ROLE_USER']
            break
        }

        const check = availableRoles.filter(val =>
          this.user.roles.includes(val)
        )

        return check.length > 0
      } else {
        return false
      }
    },
    checkPage () {
      const name = this.$route.name.split('___')
      if (name && name[0]) {
        this.isHomePage = name[0] === 'index'
        this.isCheckoutPage = name[0] === 'checkout'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.new-footer {
  padding: 48px 0 32px 0;
  background-color: $primary;
  color: white;
  &.checkout-footer {
    margin-top: 100px;
  }
  .footer-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 64px;
    svg {
      display: block;
    }
    .footer-socials {
      display: flex;
      align-items: center;
      gap: 8px;
      svg {
        display: block;
        width: 28px;
        height: 28px;
        path {
          fill: white;
        }
      }
    }
  }
  .footer-sections {
    display: grid;
    grid-auto-columns: calc((100% - 2*60px) / 3);
    align-items: start;
    justify-content: center;
    grid-gap: 60px;
    @include touch {
      display: flex;
      flex-direction: column;
      grid-gap: 24px;
    }
    & > div {
      &:nth-child(2) {
        grid-column: 2;
        @include touch {
          grid-column: 1;
          grid-row: 2;
        }
      }
      &:nth-child(3) {
        grid-column: 3;
        @include touch {
          grid-column: 1;
          grid-row: 3;
        }
      }
    }
    .footer-section-container {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
    .footer-section {
      width: 100%;
      .footer-section-title {
        display: flex;
        gap: 12px;
        margin-top: 24px;
        margin-bottom: 24px;
        padding-bottom: 6px;
        font-size: 20px;
        font-weight: 600;
        border-bottom: 1px solid rgba(white, 0.33);
        .footer-arrow{
          width:20px;
          filter: brightness(0) invert(1);
        }
      }
      p b {
        font-weight: 600;
      }
      a {
        color: white;
      }
    }
  }
  .footer-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 48px;
    font-size: 14px;
  }
}
</style>
