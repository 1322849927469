<template>
  <div class="menu-mobile modal-card">
    <div class="modal-card-header">
      <div class="container main-container">
        <div class="logo">
          <logo />
          <select-language />
        </div>
      </div>
    </div>
    <div class="menu-content modal-card-body">
      <div class="container main-container">
        <div class="menu-items">
          <div>
            <span class="small-header">
              {{ $t('products.title') }}
            </span>
            <template v-if="project === 'rzaska'">
              <product-tiles />
            </template>
            <template v-if="project === 'schuette'">
              <category-tiles />
            </template>
          </div>
          <div>
            <span class="small-header">
              {{ $t('fields.support') }}
            </span>
            <a
              :href="user && check('seller') ? localePath('admin-messenger') : localePath('messenger')"
              class="menu-item"
              @click.prevent="redirect(user && check('seller') ? localePath('admin-messenger') : localePath('messenger'))"
            >
              <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18"><title>Messages</title><path d="M2.2,16.2,6,15.1l.4-.1.4.1a7.8,7.8,0,0,0,2.2.3c4.1,0,7.5-3.1,7.5-6.9S13.1,1.6,9,1.6s-7.5,3-7.5,6.8a6.82,6.82,0,0,0,1.2,3.8l.4.6-.2.7-.7,2.7M1.5,18a1.1,1.1,0,0,1-1-1.1l1-3.9A7.46,7.46,0,0,1,0,8.4C0,3.8,4,0,9,0s9,3.8,9,8.4-4,8.4-9,8.4a11.53,11.53,0,0,1-2.6-.3L2,17.8A.76.76,0,0,1,1.5,18Z" fill="#e32f31" /><path d="M6.3,6h5.4a.79.79,0,0,1,.8.8h0a.79.79,0,0,1-.8.8H6.3a.79.79,0,0,1-.8-.8h0A.79.79,0,0,1,6.3,6Z" fill="#e32f31" /><path d="M6.3,9.5h5.4a.79.79,0,0,1,.8.8h0a.79.79,0,0,1-.8.8H6.3a.79.79,0,0,1-.8-.8h0A.79.79,0,0,1,6.3,9.5Z" fill="#e32f31" /></svg>
              <span :class="countNewMessages ? 'has-text-weight-semibold' : ''">
                {{ $t('messenger.title') }}
                <span v-if="countNewMessages">({{ countNewMessages }})</span>
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-card-foot">
      <div class="main-container container">
        <a
          v-if="!user"
          :href="localePath('auth-login')"
          class="button is-fullwidth mb-3"
          @click.prevent="redirect(localePath('auth-login'))"
        >
          {{ $t('header.sign_in') }}
        </a>
        <b-dropdown v-else class="menu-item">
          <div slot="trigger" slot-scope="{ active }" class="avatar-button">
            <avatar profile :name="user.displayName || 'user'" :avatar-url="user.avatar" />
          </div>
          <b-dropdown-item v-if="check('user')" has-link>
            <a :href="localePath('profile-orders')" @click.prevent="redirect(localePath('profile-orders'))">
              {{ $t('header.myAccount') }}
            </a>
          </b-dropdown-item>

          <b-dropdown-item v-if="check('seller')" has-link>
            <a :href="localePath('admin-orders')" @click.prevent="redirect(localePath('admin-orders'))">
              {{ $t('header.seller_panel') }}
            </a>
          </b-dropdown-item>

          <b-dropdown-item @click.native.prevent="logout(); closeMenu()">
            {{ $t('actions.logout') }}
          </b-dropdown-item>
        </b-dropdown>
        <menu-cart no-icon />
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from 'marketplace-front-core/components/shared/Avatar'
import SelectLanguage from 'marketplace-front-core/components/shared/SelectLanguage'
import MenuCart from 'marketplace-front-core/components/shared/MenuCart'
import ProductTiles from './ProductTiles'
import CategoryTiles from './CategoryTiles'
import Logo from './Logo'
export default {
  components: { Avatar, Logo, SelectLanguage, CategoryTiles, MenuCart, ProductTiles },
  computed: {
    countNewMessages () {
      const count = this.$store.state.websocket.unreadMessage?.unreadTickets || null
      return count && count > 0 ? count : null
    }
  },
  methods: {
    check (key) {
      if (this.user) {
        let availableRoles = []
        switch (key) {
          case 'seller':
            availableRoles = ['ROLE_SELLER_OWNER', 'ROLE_SELLER']
            break
          case 'user':
            availableRoles = ['ROLE_SUPER_ADMIN', 'ROLE_USER']
            break
        }

        const check = availableRoles.filter(val =>
          this.user.roles.includes(val)
        )

        return check.length > 0
      } else {
        return false
      }
    },
    closeMenu () {
      this.$store.dispatch('page/isMenuOpen', false)
    },
    redirect (link) {
      this.$store.dispatch('page/isMenuOpen', false)

      if (!this.disableRedirect) {
        this.$emit('update:disableRedirect', true)
        this.$router.push(link, () => {
          this.$emit('update:disableRedirect', false)
        }, () => {
          this.$emit('update:disableRedirect', false)
        })
      }
    }
  }
}
</script>
