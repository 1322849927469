<template>
  <div class="rating-average">
    <b-rate v-model="averageValue" size="is-small" disabled />
    <div class="rating-average-value">
      {{ averageValue.toFixed(1) }} <span v-if="totalValue">({{ totalValue }})</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RatingAverage',
  props: {
    average: {
      type: Number,
      default: 5
    },
    total: {
      type: Number,
      default: 0
    }
  },
  computed: {
    averageValue () {
      return this.average
    },
    totalValue () {
      return this.total
    }
  }
}
</script>

<style lang="scss" scoped>
$star-bg-color: #FCC01D;
.rating-average {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  :deep(.rate) {
    margin-bottom: 0;
    .rate-item {
      cursor: pointer !important;
      span {
        color: $star-bg-color !important;
      }
    }
  }
}
</style>
