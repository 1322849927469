<template>
  <b-modal :active.sync="active" full-screen :can-cancel="false">
    <div class="modal-card" style="width: auto">
      <section class="modal-card-body p-t-50 p-b-50">
        <div class="container">
          <div class="columns is-centered">
            <div class="content column is-8">
              <div class="columns is-mobile">
                <div class="column">
                  <h1 class="title is-3">
                    Ikony
                  </h1>
                </div>
                <div class="column is-narrow">
                  <b-button @click="active = false">
                    Zamknij
                  </b-button>
                </div>
              </div>
              <b-table
                :data="Object.keys(icons)"
                class="icons-table"
                @mouseenter="showButton"
                @click="copyValue"
              >
                <b-table-column v-slot="props" cell-class="is-narrow">
                  <b-icon :icon="props.row" />
                </b-table-column>
                <b-table-column v-slot="props" cell-class="is-narrow">
                  {{ props.row }}
                </b-table-column>
                <b-table-column v-slot="props">
                  <div v-if="hover === props.row" class="has-text-grey">
                    Kliknij, aby skopiować nazwę ikony
                  </div>
                </b-table-column>
              </b-table>

              <input v-if="showInput" ref="input" v-model="input">

              <b-button class="is-fullwidth mt-5" @click="active = false">
                Zamknij
              </b-button>
            </div>
          </div>
        </div>
      </section>
    </div>
  </b-modal>
</template>

<script>
import icons from './../icons/core-icons.json'
export default {
  data () {
    return {
      active: false,
      icons,
      hover: null,
      input: '',
      showInput: false
    }
  },
  methods: {
    show () {
      this.active = true
    },
    copyValue (text) {
      this.input = text
      this.showInput = true
      this.$nextTick(() => {
        const textToCopy = this.$refs.input
        textToCopy.select()
        document.execCommand('copy')
        document.getSelection().empty()
        this.$buefy.toast.open({
          message: 'Skopiowano "' + text + '"',
          type: 'is-success'
        })
        this.showInput = false
      })
    },
    showButton (index) {
      this.hover = index
    }
  }
}
</script>
