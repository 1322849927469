<template>
  <div class="main-body">
    <layout-header />
    <div class="container main-container main-content">
      <nuxt />
    </div>
    <footer-schuette v-if="project === 'schuette' && !isCheckout" />
    <footer-rzaska v-if="project === 'rzaska' && !isCheckout" />
    <global-layout />
  </div>
</template>

<script>
import GlobalLayout from 'marketplace-front-core/components/layout/GlobalLayout'
import LayoutHeader from '~/components/layout/Header'
import FooterSchuette from '~/components/layout/FooterSchuette'
import FooterRzaska from '~/components/layout/FooterRzaska'

export default {
  name: 'LayoutDefault',
  components: {
    LayoutHeader,
    FooterSchuette,
    FooterRzaska,
    GlobalLayout
  },
  computed: {
    isCheckout () {
      return this.$route.name.split('___')[0] === 'checkout'
    }
  }
}
</script>
