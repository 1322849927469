
export default function ({ from, store }) {
  if (from) {
    const name = from.name.split('___')
    if (!(name && name[0] && name[0] === 'product')) {
      store.dispatch('page/productScroll', true)
      // window.scrollTo(0, 0)
    }
  }
}
