import Vue from 'vue'

Vue.directive('custom-click-outside', {
  bind (el, binding, vnode) {
    el.clickOutsideEvent = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        // poprawka aby nie działało podczas użycia modalu w ckeditorze
        const element = document.body.getElementsByClassName('cke_dialog_background_cover')
        if (!((element && element[0] && element[0].style && element[0].style.display !== 'none') || (event && event.target && event.target.classList.contains('cke_dialog_ui_button')))) {
          vnode.context[binding.expression](event)
        }
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  }
})
