<template>
  <div>
    <div :class="['side-cart-background', {'is-active': isOpenedCart}]" />
    <div v-custom-click-outside="closeCart" tabindex="-1" :class="['side-cart', {'is-active': isOpenedCart}]">
      <div class="side-cart-content">
        <b-loading :active.sync="loadingCart" :is-full-page="false" class="cart-loading" />
        <div class="columns is-mobile is-vcentered">
          <div class="column">
            <span class="title is-4">
              {{ $t('cart.title') }} ({{ getCart && getCart.quantity ? getCart.quantity : 0 }})
            </span>
          </div>
          <div class="column is-narrow side-cart-close">
            <b-icon icon="clear" class="pointer" @click.native="closeCart" />
          </div>
        </div>
        <cart />
        <payments-icons class="is-flex-justify-center m-t-20" />
      </div>
    </div>
  </div>
</template>

<script>
import PaymentsIcons from 'marketplace-front-core/components/shared/PaymentsIcons'
import Cart from 'marketplace-front-core/components/shared/Cart'

export default {
  components: { Cart, PaymentsIcons },
  computed: {
    isOpenedCart () {
      return this.$store.state.page.isOpenedCart
    },
    getCart () {
      return this.$store.state.page.cart
    },
    loadingCart () {
      return this.$store.state.page.loadingCart
    }
  },
  watch: {
    isOpenedCart (val) {
      this.toggleClass(val)
    }
  },
  mounted () {
    this.toggleClass(this.isOpenedCart)
  },
  beforeDestroy () {
    this.toggleClass(false)
  },
  methods: {
    closeCart () {
      this.$store.dispatch('page/isOpenedCart', false)
    },
    toggleClass (active) {
      if (active) {
        document.documentElement.classList.add('is-clipped-side-cart')
      } else {
        document.documentElement.classList.remove('is-clipped-side-cart')
      }
    }
  }
}
</script>
