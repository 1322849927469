export default function debounce (fn, delay = 500) {
  let timeoutID = null
  return function () {
    clearTimeout(timeoutID)
    const args = arguments
    const that = this
    timeoutID = setTimeout(() => {
      fn.apply(that, args)
    }, delay)
  }
}
