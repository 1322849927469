<script>
export default {
  props: {
    value: { type: [Object, Array], default: null },
    tag: { type: String, default: 'div' },
    options: { type: Object, default: () => {} }
  },
  render (createElement) {
    let nodes = this.value
    if (!Array.isArray(nodes)) {
      nodes = [nodes]
    }
    // return createElement(this.tag, this.options, nodes)
    return this.value
  }
}
</script>
