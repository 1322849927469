import { polishPlurals } from 'polish-plurals'

const formatFileSize = (size) => {
  const units = ['Byte', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const threshold = 1024
  size = Number(size) * threshold
  const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(threshold))
  return `${(size / threshold ** i).toFixed(2) * 1} ${units[i]}`
}

const characterLabel = polishPlurals.bind(null, 'znak', 'znaki', 'znaków')

export default {
  pl: {
    messages: {
      _default: () => 'Pole jest nieprawidłowe.',
      after: (field, [target]) =>
        `Wprowadzona data musi być większa niż ${target}.`,
      alpha: () => 'Pole może zawierać tylko litery.',
      alpha_dash: () =>
        'Pole może zawierać litery, cyfry oraz myślnik lub podkreślnik.',
      alpha_num: () => 'Pole może zawierać tylko litery i cyfry.',
      alpha_spaces: () => 'Pole może zawierać tylko litery oraz spacje.',
      before: (field, [target]) => `Pole musi być przed ${target}.`,
      between: (field, [min, max]) =>
        `Pole musi być pomiędzy ${min} oraz ${max}.`,
      confirmed: (field, [confirmedField]) =>
        `Pole nie zgadza się z polem potwierdzającym ${confirmedField}.`,
      credit_card: () => 'Pole musi być poprawnym numerem karty kredytowej.',
      date_between: (field, [min, max]) =>
        `Pole musi zawierać się między ${min} a ${max}.`,
      date_format: (field, [format]) =>
        `Pole musi pasować do formatu ${format}.`,
      decimal: (field, [decimals = '*'] = []) =>
        `Pole musi być liczbą i może zawierać ${
          decimals === '*' ? '' : decimals
        } miejsca po przecinku.`,
      digits: (field, [length]) =>
        `Pole musi być liczbą i dokładnie ${length} cyfr.`,
      dimensions: (field, [width, height]) =>
        `Obraz musi być szeroki na ${width} pikseli i wysoki na ${height} pikseli.`,
      email: () => 'Pole musi być poprawnym adresem email.',
      excluded: () => 'Pole musi być poprawną wartością.',
      ext: () => 'Plik musi być poprawnym plikiem.',
      image: () => 'Pole musi być obrazem.',
      included: () => 'Pole musi być poprawną wartością.',
      integer: () => 'Pole musi być liczbą całkowitą.',
      ip: () => 'Pole musi być poprawnym adresem IP.',
      length: (field, [length, max]) => {
        if (max) {
          return `Pole musi mieć długość od ${length} do ${max} znaków.`
        }

        return `Pole musi mieć długość ${length} ${characterLabel(length)}.`
      },
      max: (field, [length]) =>
        `Pole nie może być dłuższe niż ${length} ${characterLabel(length)}.`,
      max_value: (field, [max]) => `Pole musi mieć maksymalną wartość ${max}.`,
      mimes: () => 'Plik musi posiadać poprawne rozszerzenie.',
      min: (field, [length]) =>
        `Pole musi mieć co najmniej ${length} ${characterLabel(length)}.`,
      min_value: (field, [min]) => `Pole musi mieć minimalną wartość ${min}.`,
      numeric: () => 'Pole może zawierać tylko cyfry.',
      regex: () => 'Format pola jest nieodpowiedni.',
      required: () => 'Pole jest wymagane.',
      size: (field, [size]) =>
        `Plik musi być mniejszy niż ${formatFileSize(size)}.`,
      url: () => 'Pole nie jest poprawnym URL.',
      uniqueEmail: () => 'Posiadasz już u nas zarejestrowane konto',
      uniquePhone: () => 'Unikalny numer telefonu'
    },
    custom: {
      shippingGroup: {
        required: () => 'Należy wybrać sposób dostawy.'
      },
      paymentGroup: {
        required: () => 'Należy wybrać sposób płatności.'
      }
    }
  },
  de: {
    messages: {
      _default: () => 'Feld ist falsch.',
      after: (field, [target]) =>
        `Das eingegebene Datum muss größer sein als ${target}.`,
      alpha: () => 'Ein Feld darf nur Buchstaben enthalten..',
      alpha_dash: () =>
        'Ein Feld kann Buchstaben, Zahlen und einen Bindestrich oder Unterstrich enthalten..',
      alpha_num: () => 'Das Feld darf nur Buchstaben und Zahlen enthalten..',
      alpha_spaces: () =>
        'Das Feld darf nur Buchstaben und Leerzeichen enthalten..',
      before: (field, [target]) => `Das Feld muss vor dem ${target}.`,
      between: (field, [min, max]) =>
        `Das Feld muss zwischen ${min} und ${max}.`,
      confirmed: (field, [confirmedField]) =>
        `Das Feld stimmt nicht mit dem Bestätigungsfeld überein. ${confirmedField}.`,
      credit_card: () => 'Das Feld muss eine gültige Kreditkartennummer sein..',
      date_between: (field, [min, max]) =>
        `Das Feld muss zwischen ${min} bis ${max}.`,
      date_format: (field, [format]) =>
        `Das Feld muss dem Format entsprechen ${format}.`,
      decimal: (field, [decimals = '*'] = []) =>
        `Ein Feld muss eine Zahl sein und kann Folgendes enthalten ${
          decimals === '*' ? '' : decimals
        } die Nachkommastellen.`,
      digits: (field, [length]) =>
        `Das Feld muss eine Zahl und genau ${length} stellig sein.`,
      dimensions: (field, [width, height]) =>
        `Das Bild muss ${width} Pixel breit und ${height} Pixel hoch sein..`,
      email: () => 'Das Feld muss eine gültige E-Mail-Adresse sein.',
      excluded: () => 'Das Feld muss ein gültiger Wert sein..',
      ext: () => 'Die Datei muss eine gültige Datei sein..',
      image: () => 'Das Feld muss ein Bild sein.',
      included: () => 'Das Feld muss ein gültiger Wert sein.',
      integer: () => 'Das Feld muss eine ganze Zahl sein.',
      ip: () => 'Das Feld muss eine gültige IP-Adresse sein.',
      length: (field, [length, max]) => {
        if (max) {
          return `Das Feld muss zwischen ${length} und ${max} Zeichen lang sein..`
        }

        return `Das Feld muss ${length} sein..`
      },
      max: (field, [length]) =>
        `Das Feld darf nicht länger sein als ${length}.`,
      max_value: (field, [max]) =>
        `Das Feld muss einen Maximalwert von ${max}.`,
      mimes: () => 'Die Datei muss eine gültige Erweiterung haben..',
      min: (field, [length]) =>
        `Das Feld muss lang sein für mindestens ${length}.`,
      min_value: (field, [min]) =>
        `Das Feld muss mindestens wie folgt aussehen ${min}.`,
      numeric: () => 'Das Feld darf nur Ziffern enthalten..',
      regex: () => 'Feldformat ist unangemessen.',
      required: () => 'Bitte füllen Sie auch dieses Feld aus.',
      size: (field, [size]) =>
        `Die Datei muss kleiner sein als ${formatFileSize(size)}.`,
      url: () => 'Das Feld ist keine gültige URL.',
      uniqueEmail: () => 'Sie haben schon ein bestehendes Kundenkonto',
      uniquePhone: () => 'Eindeutige Telefonnummer'
    },
    custom: {
      shippingGroup: {
        required: () => 'Bitte wählen Sie die Versandart aus.'
      },
      paymentGroup: {
        required: () => 'Bitte wählen Sie Ihre Zahlungsmethode aus.'
      }
    }
  },
  fr: {
    messages: {
      _default: () => 'Le champ est faux.',
      after: (field, [target]) =>
        `La date saisie doit être postérieure à ${target}.`,
      alpha: () => 'Un champ ne peut contenir que des lettres..',
      alpha_dash: () =>
        "Un champ peut contenir des lettres, des chiffres et un trait d'union ou un trait de soulignement..",
      alpha_num: () =>
        'Le champ ne peut contenir que des lettres et des chiffres..',
      alpha_spaces: () =>
        'Le champ ne peut contenir que des lettres et des espaces..',
      before: (field, [target]) => `Le champ doit être avant le${target}.`,
      between: (field, [min, max]) =>
        `Le champ doit être compris entre ${min} und ${max}.`,
      confirmed: (field, [confirmedField]) =>
        `Le champ ne correspond pas au champ de confirmation. ${confirmedField}.`,
      credit_card: () =>
        'Le champ doit être un numéro de carte de crédit valide..',
      date_between: (field, [min, max]) =>
        `Le champ doit être compris entre ${min} bis ${max}.`,
      date_format: (field, [format]) =>
        `Le champ doit correspondre au format ${format}.`,
      decimal: (field, [decimals = '*'] = []) =>
        `Un champ doit être un nombre et peut contenir les éléments suivants${
          decimals === '*' ? '' : decimals
        } die Nachkommastellen.`,
      digits: (field, [length]) =>
        `Le champ doit être un nombre et exact ${length} être un chiffre.`,
      dimensions: (field, [width, height]) =>
        `L'image doit${width} Pixels de large et ${height} Être en pixels de haut..`,
      email: () => 'Le champ doit être une adresse email valide.',
      excluded: () => 'Le champ doit être une valeur valide..',
      ext: () => 'Le fichier doit être un fichier valide..',
      image: () => 'Le champ doit être une image.',
      included: () => 'Le champ doit être une valeur valide.',
      integer: () => 'Le champ doit être un entier.',
      ip: () => 'Le champ doit être une adresse IP valide.',
      length: (field, [length, max]) => {
        if (max) {
          return `Le champ doit être compris entre ${length} et ${max} Longs caractères..`
        }

        return `Le terrain doit ${length} être..`
      },
      max: (field, [length]) =>
        `Das Feld darf nicht länger sein als ${length}.`,
      max_value: (field, [max]) =>
        `Le champ doit avoir une valeur maximale de ${max}.`,
      mimes: () => 'Le fichier doit avoir une extension valide..',
      min: (field, [length]) =>
        `Le champ doit être long pendant au moins ${length}.`,
      min_value: (field, [min]) =>
        `Le champ doit ressembler au moins à ce qui suit ${min}.`,
      numeric: () => 'Le champ ne peut contenir que des chiffres..',
      regex: () => 'Le format du champ est inapproprié.',
      required: () => 'Le champ est obligatoire.',
      size: (field, [size]) =>
        `Le fichier doit être plus petit que ${formatFileSize(size)}.`,
      url: () => "Le champ n'est pas une URL valide.",
      uniqueEmail: () => 'Vous avez déjà un compte client existant',
      uniquePhone: () => 'Numéro de téléphone unique'
    },
    custom: {
      shippingGroup: {
        required: () => "Veuillez sélectionner le mode d'expédition."
      },
      paymentGroup: {
        required: () => 'Veuillez sélectionner votre mode de paiement.'
      }
    }
  },
  en: {
    messages: {
      _default: () => 'The value is not valid',
      after: (field, [target, inclusion]) =>
        `The must be after ${inclusion ? 'or equal to ' : ''}${target}`,
      alpha: () => 'The field may only contain alphabetic characters',
      alpha_dash: () =>
        'The field may contain alpha-numeric characters as well as dashes and underscores',
      alpha_num: () => 'The field may only contain alpha-numeric characters',
      alpha_spaces: () =>
        'The field may only contain alphabetic characters as well as spaces',
      before: (field, [target, inclusion]) =>
        `The must be before ${inclusion ? 'or equal to ' : ''}${target}`,
      between: (field, [min, max]) =>
        `The field must be between ${min} and ${max}`,
      confirmed: () => 'The confirmation does not match',
      credit_card: () => 'The field is invalid',
      date_between: (field, [min, max]) =>
        `The must be between ${min} and ${max}`,
      date_format: (field, [format]) => `The must be in the format ${format}`,
      decimal: (field, [decimals = '*'] = []) =>
        `The field must be numeric and may contain${
          !decimals || decimals === '*' ? '' : ' ' + decimals
        } decimal points`,
      digits: (field, [length]) =>
        `The field must be numeric and contains exactly ${length} digits`,
      dimensions: (field, [width, height]) =>
        `The field must be ${width} pixels by ${height} pixels`,
      email: () => 'The field must be a valid email',
      excluded: () => 'The field must be a valid value',
      ext: () => 'The field must be a valid file',
      image: () => 'The field must be an image',
      included: () => 'The field must be a valid value',
      integer: () => 'The field must be an integer',
      ip: () => 'The field must be a valid ip address',
      ip_or_fqdn: () => 'The field must be a valid ip address or FQDN',
      length: (field, [length, max]) => {
        if (max) {
          return `The length must be between ${length} and ${max}`
        }

        return `The length must be ${length}`
      },
      max: (field, [length]) =>
        `The field may not be greater than ${length} characters`,
      max_value: (field, [max]) => `The field must be ${max} or less`,
      mimes: () => 'The field must have a valid file type',
      min: (field, [length]) =>
        `The field must be at least ${length} characters`,
      min_value: (field, [min]) => `The field must be ${min} or more`,
      numeric: () => 'The field may only contain numeric characters',
      regex: () => 'The field format is invalid',
      required: () => 'The field is required',
      required_if: (field, [target]) =>
        `The field is required when the ${target} field has this value`,
      size: (field, [size]) =>
        `The size must be less than ${formatFileSize(size)}`,
      url: () => 'The field is not a valid URL',
      uniqueEmail: () => 'You already have an existing Client Account with us',
      uniquePhone: () => 'Unique phone number'
    },
    custom: {
      shippingGroup: {
        required: () => 'Please select the shipping method'
      },
      paymentGroup: {
        required: () => 'Please select your payment method'
      }
    }
  },
  es: {
    messages: {
      _default: () => 'El contenido de este campo es incorrecto',
      after: (field, [target]) =>
        `La fecha ingresada debe ser mayor que ${target}`,
      alpha: () => 'Este campo sólo puede contener letras',
      alpha_dash: () =>
        'Este campo puede contener letras, números y un guión o un guión bajo',
      alpha_num: () => 'Este campo sólo puede contener letras y números',
      alpha_spaces: () => 'Este campo sólo puede contener letras y espacios',
      before: (field, [target]) => `Este campo debe estar antes de ${target}`,
      between: (field, [min, max]) =>
        `Este campo debe estar entre ${min} y ${max}`,
      confirmed: (field, [confirmedField]) =>
        `El contenido de este campo no coincide con el de confirmación ${confirmedField}`,
      credit_card: () =>
        'Este campo debe contener un número de tarjeta de crédito válido',
      date_between: (field, [min, max]) =>
        `El contenido de este campo debe estar entre ${min} y ${max}`,
      date_format: (field, [format]) =>
        `El contenido de este campo debe corresponder al formato ${format}`,
      decimal: (field, [decimals = '*'] = []) =>
        `Este campo debe contener un número de ${
          decimals === '*' ? '' : decimals
        } decimales`,
      digits: (field, [length]) =>
        `Este campo debe contener un número de ${length} digitos`,
      dimensions: (field, [width, height]) =>
        `La imagen debe medir ${width} píxeles de ancho y ${height} píxeles de alto`,
      email: () =>
        'Este campo debe contener una dirección de correo electrónico válida',
      excluded: () => 'Este campo debe contener un valor válido',
      ext: () => 'El archivo debe ser un archivo válido',
      image: () => 'Este campo debe contener una imagen',
      included: () => 'Este campo debe contener un valor válido',
      integer: () => 'Este campo debe contener un número completo',
      ip: () => 'Este campo debe contener una dirección IP válida',
      length: (field, [length, max]) => {
        if (max) {
          return `Este campo debe contener entre ${length} y ${max} caracteres`
        }

        return `El contenido de este campo debe tener ${length} de longitud`
      },
      max: (field, [length]) =>
        `El contenido de este campo no puede exceder ${length}`,
      max_value: (field, [max]) =>
        `El contenido de este campo debe tener un valor máximo de ${max}`,
      mimes: () => 'El archivo debe tener una extensión válida',
      min: (field, [length]) => `Este campo debe contener al menos ${length}`,
      min_value: (field, [min]) =>
        `El contenido de este campo debe ser al menos ${min}`,
      numeric: () => 'Este campo sólo puede contener números',
      regex: () => 'El formato incluido en este campo es inapropiado',
      required: () => 'Este campo es obligatorio',
      size: (field, [size]) =>
        `El archivo debe ser menor que ${formatFileSize(size)}`,
      url: () => 'El contenido de este campo no es una URL válida',
      uniqueEmail: () => 'Ya tiene una cuenta de cliente',
      uniquePhone: () => 'Número de teléfono único'
    },
    custom: {
      shippingGroup: {
        required: () => 'Seleccione un método de envío.'
      },
      paymentGroup: {
        required: () => 'Seleccione un método de pago.'
      }
    }
  },
  it: {
    messages: {
      _default: () => 'Il contenuto del campo non è valido.',
      after: (field, [target]) =>
        `Il contenuto del campo deve essere successivo a ${target}.`,
      alpha: () => 'Il campo può contenere solo lettere.',
      alpha_dash: () =>
        'Il campo può contenere lettere, numeri e trattini o trattini bassi.',
      alpha_num: () => 'Il campo può contenere solo lettere e numeri.',
      alpha_spaces: () => 'Il campo può contenere solo lettere e spazi.',
      before: (field, [target]) =>
        `Il contenuto del campo deve essere precedente a ${target}.`,
      between: (field, [min, max]) =>
        `Il contenuto del campo deve essere compreso tra ${min} e ${max}.`,
      confirmed: (field, [confirmedField]) =>
        `Il contenuto del campo non corrisponde a quello di conferma ${confirmedField}.`,
      credit_card: () =>
        'Il campo deve contenere un numero di carta di credito valido.',
      date_between: (field, [min, max]) =>
        `La data deve essere compresa tra ${min} e ${max}.`,
      date_format: (field, [format]) =>
        `La data deve corrispondere al formato ${format}.`,
      decimal: (field, [decimals = '*'] = []) =>
        `Il valore deve essere un numero e può contenere ${
          decimals === '*' ? '' : decimals
        } decimali.`,
      digits: (field, [length]) =>
        `Il campo deve essere un numero di esattamente ${length} cifre.`,
      dimensions: (field, [width, height]) =>
        `L'immagine deve essere larga ${width} pixel e alta ${height} pixel.`,
      email: () => 'Il campo deve contenere un indirizzo e-mail valido.',
      excluded: () => 'Il campo deve contenere un valore valido.',
      ext: () => 'Il campo deve contenere un file valido.',
      image: () => "Il campo deve contenere un'immagine.",
      included: () => 'Il campo deve contenere un valore valido.',
      integer: () => 'Il campo deve contenere un numero intero.',
      ip: () => 'Il campo deve contenere un indirizzo ip valido.',
      length: (field, [length, max]) => {
        if (max) {
          return `Il contenuto del campo deve essere compreso tra i ${length} e i ${max} caratteri.`
        }

        return `Il contenuto del campo deve avere una lunghezza di ${length} ${characterLabel(
          length
        )}.`
      },
      max: (field, [length]) =>
        `Il contenuto del campo non deve essere più lungo di ${length} ${characterLabel(
          length
        )}.`,
      max_value: (field, [max]) =>
        `Il campo deve avere un valore massimo di ${max}.`,
      mimes: () => "Il file deve avere un'estensione valida.",
      min: (field, [length]) =>
        `Il campo deve contenere almeno ${length} ${characterLabel(length)}.`,
      min_value: (field, [min]) =>
        `Il campo deve contenere un valore minimo di ${min}.`,
      numeric: () => 'Il campo può contenere solo cifre.',
      regex: () => 'Formato non valido per questo campo.',
      required: () => 'Campo obbligatorio.',
      size: (field, [size]) =>
        `Il file deve essere più piccolo di ${formatFileSize(size)}.`,
      url: () => 'Il campo non contiene un URL valido.',
      uniqueEmail: () => 'Disponete già di un account con noi',
      uniquePhone: () => 'Numero di telefono univoco'
    },
    custom: {
      shippingGroup: {
        required: () => 'Selezionare il metodo di spedizione.'
      },
      paymentGroup: {
        required: () => 'Selezionare il metodo di pagamento.'
      }
    }
  },
  nl: {
    messages: {
      _default: () => 'Het veld is onjuist.',
      after: (field, [target]) =>
        `De ingevoerde datum moet groter zijn dan ${target}.`,
      alpha: () => 'Het veld kan alleen letters bevatten.',
      alpha_dash: () =>
        'Het veld kan letters, cijfers en een koppelteken of onderstrepingsteken bevatten.',
      alpha_num: () => 'Het veld kan alleen letters en cijfers bevatten.',
      alpha_spaces: () => 'Het veld kan alleen letters en spaties bevatten.',
      before: (field, [target]) => `Het veld moet voor ${target}.`,
      between: (field, [min, max]) =>
        `Het veld moet tussen ${min} en ${max} liggen.`,
      confirmed: (field, [confirmedField]) =>
        `Veld komt niet overeen met bevestigingsveld ${confirmedField}.`,
      credit_card: () => 'Het veld moet een geldig creditcardnummer zijn.',
      date_between: (field, [min, max]) =>
        `Het veld moet tussen ${min} en ${max} liggen.`,
      date_format: (field, [format]) =>
        `Het veld moet voldoen aan het formaat ${format}.`,
      decimal: (field, [decimals = '*'] = []) =>
        `Het veld moet een getal zijn en kan het volgende bevatten ${
          decimals === '*' ? '' : decimals
        } decimale plaatsen.`,
      digits: (field, [length]) =>
        `Het veld moet een getal zijn en precies ${length} cijfers.`,
      dimensions: (field, [width, height]) =>
        `De afbeelding moet breed zijn bij ${width} pixels en hoog op ${height} pixels.`,
      email: () => 'Het veld moet een geldig e-mailadres zijn.',
      excluded: () => 'Het veld moet een geldige waarde hebben.',
      ext: () => 'Het bestand moet een geldig bestand zijn.',
      image: () => 'Het veld moet een afbeelding zijn.',
      included: () => 'Het veld moet een geldige waarde hebben.',
      integer: () => 'Het veld moet een geheel getal zijn.',
      ip: () => 'Het veld moet een geldig IP-adres zijn.',
      length: (field, [length, max]) => {
        if (max) {
          return `Het veld moet tussen ${length} en ${max} karakters lang zijn.`
        }
        return `Het veld moet een lengte hebben van ${length} ${characterLabel(
          length
        )}.`
      },
      max: (field, [length]) =>
        `Het veld mag niet langer zijn dan ${length} ${characterLabel(
          length
        )}.`,
      max_value: (field, [max]) =>
        `Het veld moet een maximale waarde hebben ${max}.`,
      mimes: () => 'Het bestand moet een geldige extensie hebben.',
      min: (field, [length]) =>
        `Het veld moet ten minste ${length} ${characterLabel(length)}.`,
      min_value: (field, [min]) =>
        `Het veld moet een minimumwaarde hebben ${min}.`,
      numeric: () => 'Het veld kan alleen getallen bevatten.',
      regex: () => 'De indeling van het veld is ontoereikend.',
      required: () => 'Het veld is verplicht.',
      size: (field, [size]) =>
        `Het bestand moet kleiner zijn dan ${formatFileSize(size)}.`,
      url: () => 'Het veld is geen geldige URL.',
      uniqueEmail: () => 'Je hebt al een geregistreerde account bij ons',
      uniquePhone: () => 'Uniek telefoonnummer'
    },
    custom: {
      shippingGroup: {
        required: () => 'Selecteer de leveringsmethode.'
      },
      paymentGroup: {
        required: () => 'Selecteer de betalingsmethode.'
      }
    }
  },
  no: {
    messages: {
      _default: () => 'Feltet er feil.',
      after: (field, [target]) => `Den angitte datoen må være større enn ${target}.`,
      alpha: () => 'Feltet kan bare inneholde bokstaver.',
      alpha_dash: () => 'Feltet kan inneholde bokstaver, tall og bindestrek eller understreking.',
      alpha_num: () => 'Feltet kan bare inneholde bokstaver og tall.',
      alpha_spaces: () => 'Feltet kan bare inneholde bokstaver og mellomrom.',
      before: (field, [target]) => `Feltet må være før ${target}.`,
      between: (field, [min, max]) => `Feltet må være mellom ${min} og ${max}.`,
      confirmed: (field, [confirmedField]) => `Feltet stemmer ikke overens med bekreftelsesfeltet ${confirmedField}.`,
      credit_card: () => 'Feltet må være et gyldig kredittkortnummer.',
      date_between: (field, [min, max]) => `Feltet må være mellom ${min} og ${max}.`,
      date_format: (field, [format]) => `Feltet må passe til formatet ${format}.`,
      decimal: (field, [decimals = '*'] = []) => `Feltet må være et tall og kan inneholde ${decimals === '*' ? '' : decimals} desimaler.`,
      digits: (field, [length]) => `Feltet må være et tall og nøyaktig ${length} sifre.`,
      dimensions: (field, [width, height]) => `Bildet må være bredt på ${width} piksler og høyt på ${height} piksler.`,
      email: () => 'Feltet må være en gyldig e-postadresse.',
      excluded: () => 'Feltet må være en gyldig verdi.',
      ext: () => 'Filen må være en gyldig fil.',
      image: () => 'Feltet må være et bilde.',
      included: () => 'Feltet må være en gyldig verdi.',
      integer: () => 'Feltet må være et heltall.',
      ip: () => 'Feltet må være en gyldig IP-adresse.',
      length: (field, [length, max]) => {
        if (max) {
          return `Feltet må ha en lengde fra ${length} til ${max} tegn.`
        }

        return `Feltet må ha en lengde på ${length} ${characterLabel(length)}.`
      },
      max: (field, [length]) => `Feltet må ikke være lengre enn ${length} ${characterLabel(length)}.`,
      max_value: (field, [max]) => `Feltet må ha en maksimumsverdi ${max}.`,
      mimes: () => 'Filen må ha riktig filtype.',
      min: (field, [length]) => `Feltet må ha minst ${length} ${characterLabel(length)}.`,
      min_value: (field, [min]) => `Feltet må ha en minimumsverdi ${min}.`,
      numeric: () => 'Feltet kan bare inneholde sifre.',
      regex: () => 'Format pola jest nieodpowiedni.',
      required: () => 'Feltet er obligatorisk.',
      size: (field, [size]) => `Filen må være mindre enn ${formatFileSize(size)}.`,
      url: () => 'Feltet er ikke en gyldig URL.',
      uniqueEmail: () => 'Du har allerede en registrert konto hos oss',
      uniquePhone: () => 'Unikt telefonnummer'
    },
    custom: {
      shippingGroup: {
        required: () => 'Velg leveringsmetode.'
      },
      paymentGroup: {
        required: () => 'Velg betalingsmåte.'
      }
    }
  }
}
