<template>
  <div class="product-tiles">
    <product-tile :disable-redirect.sync="disableRedirect" :name="products.plisy[lang].name" :product-name="products.plisy[lang].productName" :product-id="products.plisy[lang].productId" />
    <product-tile :disable-redirect.sync="disableRedirect" :name="products.rolety[lang].name" :product-name="products.rolety[lang].productName" :product-id="products.rolety[lang].productId" />
    <product-tile :disable-redirect.sync="disableRedirect" :name="products.zaslony[lang].name" :product-name="products.zaslony[lang].productName" :product-id="products.zaslony[lang].productId" />
    <product-tile :disable-redirect.sync="disableRedirect" :name="products.relaks[lang].name" :product-name="products.relaks[lang].productName" :product-id="products.relaks[lang].productId" />
  </div>
</template>

<script>
import ProductTile from './ProductTile'

export default {
  components: { ProductTile },
  data () {
    return {
      disableRedirect: false,
      lang: '',
      products: {
        plisy: {
          pl: {
            name: 'Rolety plisowane',
            productName: 'rzaska-roleta-plisowana-na-wymiar-montaz-bezinwazyjny-kolekcja-premium-white-day-bialy-kolor',
            productId: 1
          }
        },
        rolety: {
          pl: {
            name: 'Rolety',
            productName: 'rzaska-roleta-dzien-noc-montaz-2w1-inwazyjny-i-bezinwazyjny-kolor-tkaniny-white-snow-bilay',
            productId: 917
          }
        },
        zaslony: {
          pl: {
            name: 'Zasłony',
            productName: 'zaslona-zaciemniajaca-rzaska-z-oczkami-1-sztuka-szyta-na-wymiar-kolekcja-millenium-velvet-w-kolorze',
            productId: 2458
          }
        },
        relaks: {
          pl: {
            name: 'Relaks',
            productName: 'przescieradla-z-gumka-rzaska-w-rozmiarze-140x200-cm-wykonane-z-bawelny-na-wysokosc-materaca-20-cm-z-p4045',
            productId: 4045
          }
        }
      }
    }
  },
  watch: {
    currentLang: {
      immediate: true,
      handler (value) {
        this.lang = value
      }
    }
  }
}
</script>
