
<template>
  <div>
    <slot />
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: [Number, String],
      default: null
    }
  }
}
</script>
