<template>
  <div class="checkout-payments">
    <div v-if="check('klarna')" class="payment">
      <div class="img-payment" v-html="payments.klarnaRatenkauf" />
    </div>
    <div v-if="check('klarna')" class="payment">
      <div class="img-payment" v-html="payments.klarnaRechnung" />
    </div>
    <div v-if="check('mastercard')" class="payment">
      <div class="img-payment" v-html="payments.mastercard" />
    </div>
    <div v-if="check('visa')" class="payment">
      <div class="img-payment" v-html="payments.visa" />
    </div>
    <div v-if="check('payu')" class="payment">
      <div class="img-payment" v-html="payments.payU" />
    </div>
    <div v-if="check('googlePay')" class="payment">
      <div class="img-payment" v-html="payments.gPay" />
    </div>
    <div v-if="check('applePay')" class="payment">
      <div class="img-payment" v-html="payments.applePay" />
    </div>
    <div v-if="check('paypal')" class="payment">
      <div class="img-payment" v-html="payments.payPal" />
    </div>
  </div>
</template>

<script>
import klarnaRatenkauf from '~/static/core/icons/payment/klarna-ratenkauf.svg?raw'
import klarnaRechnung from '~/static/core/icons/payment/klarna-rechnung.svg?raw'
import mastercard from '~/static/core/icons/payment/mastercard.svg?raw'
import visa from '~/static/core/icons/payment/visa.svg?raw'
import payU from '~/static/core/icons/payment/payu.svg?raw'
import gPay from '~/static/core/icons/payment/gpay.svg?raw'
import applePay from '~/static/core/icons/payment/mollie-applepay.svg?raw'
import payPal from '~/static/core/icons/payment/paypal.svg?raw'
export default {
  data () {
    return {
      payments: {
        klarnaRatenkauf,
        klarnaRechnung,
        mastercard,
        visa,
        payU,
        gPay,
        applePay,
        payPal
      },
      paymentsIcons: process.env.paymentsIcons.split('|')
    }
  },
  methods: {
    check (payment) {
      return this.paymentsIcons.includes(payment)
    }
  }
}
</script>

<style lang="scss" scoped>
.checkout-payments {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  @include mobile {
    justify-content: center !important;
  }
  &.product-payments {
    .payment {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      overflow: hidden;
      .img-payment {
        height: 52px !important;
        :deep(svg) {
          display: block;
          height: 100%;
        }
      }
    }
  }
  .payment {
    .img-payment {
      display: block;
      width: auto;
      :deep(svg) {
        display: block;
        height: 64px;
        @include mobile {
          height: 56px;
        }
      }
    }
  }
}
</style>
