
import lodashClonedeep from 'lodash.clonedeep'

export default function ({ app, from, route, redirect }) {
  const queries = ['gclid']
  const toQuery = lodashClonedeep(route.query)

  if (from && from.query) {
    queries.forEach((query) => {
      if (from.query[query] && !toQuery[query]) {
        toQuery[query] = from.query[query]
      }
    })
    if (JSON.stringify(route.query) !== JSON.stringify(toQuery)) {
      return redirect({ name: route.name, params: route.params, query: toQuery })
    }
  }

  return Promise.resolve()
}
