import { Validator } from 'vee-validate'
import validatorsLang from 'marketplace-front-core/locales/validators'

Validator.localize(validatorsLang)

export default ({ app, store }) => {
  const langToMoment = (lang) => {
    return lang === 'no' ? 'nb' : lang
  }
  app.$moment.locale(langToMoment(store.state.i18n.locale))
  Validator.localize(store.state.i18n.locale, validatorsLang[store.state.i18n.locale])
  app.$cookies.set('locale', store.state.i18n.locale, { path: '/', maxAge: 60 * 60 * 24 * 365 })

  app.i18n.onLanguageSwitched = (oldLocale, newLocale) => {
    app.$moment.locale(langToMoment(newLocale))
    Validator.localize(newLocale, validatorsLang[newLocale])
  }

  app.i18n.onBeforeLanguageSwitch = (oldLocale, newLocale, isInitialSetup, context) => {
    store.dispatch('page/locale', newLocale)
    app.$cookies.set('locale', newLocale, { path: '/', maxAge: 60 * 60 * 24 * 365 })
  }
}
