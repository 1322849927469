import { getCLS, getFID, getLCP, getTTFB, getFCP } from 'web-vitals'

export default ({ app }) => {
  if (app.$ga && process.env.gtag) {
    app.router.onReady((to) => {
      webVitals()
      app.router.afterEach((to) => {
        webVitals()
      })
    })

    const webVitals = function () {
      try {
        const sendToAnalytics = function (metric) {
          // if (metric.name === 'TTFB') {
          //   const requestTime = parseInt(metric.value - metric.entries[0].requestStart)
          // }
          app.$gtag('event', metric.name, {
            event_category: 'Web Vitals',
            event_label: metric.id,
            value: Math.round(metric.name === 'CLS' ? metric.delta * 1000 : metric.delta),
            non_interaction: true
          })
        }
        getCLS(sendToAnalytics)
        getFID(sendToAnalytics)
        getLCP(sendToAnalytics)
        getFCP(sendToAnalytics)
        getTTFB(sendToAnalytics)
      } catch (err) {
        console.log('error vitals')
      }
    }
  }
}
