export default ({ app, redirect, $axios, store }) => {
  const auth = store.state.auth
  const locale = store.state.i18n.locale

  if (auth.token) {
    $axios.setToken(auth.token, 'Bearer')
  } else {
    $axios.setToken(false)
  }

  $axios.onRequest((config) => {
    // config.headers['Accept-Language'] = store.state.page.locale
    config.headers.common['Content-Language'] = store.state.page.locale
  })

  $axios.onError(async (error) => {
    if (error.response && error.response.data) {
      const data = error.response.data
      if (data.message === 'Expired JWT Token') {
        const res = await app.$api.auth.refreshToken(auth.refreshToken)
        if (res) {
          store.dispatch('auth/set', [res, false])
          error.config.headers.Authorization = 'Bearer ' + res.token
          return $axios(error.config)
        } else {
          store.dispatch('auth/reset')
          return redirect(app.localePath('auth-login', locale))
        }
      } else if (
        (data.message && data.message === 'JWT Token not found') ||
        (data.message && data.message === 'Invalid JWT Token') ||
        (data.message && data.message === 'Account is disabled.') ||
        (data.error && data.error === 'ERROR_REFRESH_TOKEN') ||
        (data.message && data.message.startsWith('Unable to load an user with property "email"'))
      ) {
        store.dispatch('auth/reset')
        return redirect(app.localePath('auth-login', locale))
      } else {
        return Promise.reject(error)
      }
    }
  })
}
