<script>
import lodashClonedeep from 'lodash.clonedeep'
import toSlugTitle from '../utils/toSlugTitle'

export default {
  data () {
    return {
      baseURL: process.env.baseURL
    }
  },
  computed: {
    user () {
      return this.$store.state.auth.user
    },
    currentLang () {
      return this.$store.state.i18n.locale
    },
    project () {
      return process.env.project
    },
    slugRegulations () {
      return JSON.parse(process.env.slugRegulations)[this.currentLang]
    },
    _localesSeller () {
      const locales = process.env.langs.split('|')
      const addingLocales = process.env.addingLangsSeller ? process.env.addingLangsSeller.split('|') : []
      return [...locales, ...addingLocales]
    }
  },
  methods: {
    _clone (obj) {
      return lodashClonedeep(obj)
    },
    sizeType (sizes) {
      if (sizes?.type && sizes.type === 'cm') {
        return this.$t('fields.cm')
      }

      if (sizes?.type && sizes.type === 'inch') {
        return this.$t('fields.inch')
      }

      return ''
    },
    getImageBySize (file, size) {
      if (file && file.sizes && size && file.sizes[size]) {
        const image = file.sizes[size]
        const imageWebp = file.sizes[`${size}-webp`]
        if (imageWebp) {
          return {
            src: imageWebp,
            webpFallback: image
          }
        } else {
          return {
            src: image,
            webpFallback: null
          }
        }
      } else {
        return {
          src: null,
          webpFallback: null
        }
      }
    },
    toSlugTitle (description) {
      return toSlugTitle(description)
    },
    toCurrency (data, type = null, currency = process.env.currency) {
      let value = data

      if (type) {
        if (data.length > 0) {
          const find = data.find(x => x.type === type)
          if (find) {
            if (find.price || find.price === 0) {
              value = find.price
            } else {
              value = find.brutto
            }
            currency = find.currency
          } else {
            return ''
          }
        } else {
          return ''
        }
      }

      if (typeof value !== 'number') {
        return ''
      }

      const formatter = new Intl.NumberFormat(this.$store.state.i18n.locale, {
        style: 'currency',
        currency,
        minimumFractionDigits: 2
      })

      return formatter.format(value)
    },
    findDescriptions (array, lang) {
      if (!lang) { lang = this.$store.state.i18n.locale }
      const findIndex = array.findIndex(x => x.lang === lang)
      if (findIndex >= 0) {
        return findIndex
      } else {
        const getId = array.push({
          id: null,
          name: null,
          nameSeo: null,
          description: null,
          descriptionSeo: null,
          lang
        })

        return getId - 1
      }
    },
    findPrices (array, type = 'base') {
      const findIndex = array.findIndex(x => x.type === type)
      if (findIndex >= 0) {
        return findIndex
      } else {
        const getId = array.push({
          id: null,
          brutto: '',
          type,
          currency: process.env.currency
        })

        return getId - 1
      }
    },
    logout () {
      this.$store.dispatch('auth/reset').then(() => {
        this.$router.push(this.localePath('index'))
        this.$store.dispatch('page/updateCart', null)

        this.$api.cart.get()
          .then((res) => {
            this.$store.dispatch('page/updateCart', res)
          })
          .catch(() => {
            this.$store.dispatch('page/updateCart', null)
          })
      })
    },
    scrollToTop (type = 'client', behavior = 'auto', top = 0) {
      window.scrollTo({ top, behavior })
    },
    scrollToFirstError () {
      if (this.errors.items && this.errors.items[0]) {
        let element = document.getElementsByName(this.errors.items[0].field)[0]
        let lastElement = null
        let top = 0
        let content = null

        while (element !== null && element.tagName !== 'HTML') {
          const classes = []
          element.classList.forEach((className) => { classes.push(className) })

          if (classes.includes('field') && !classes.includes('is-floating-label')) {
            top = element.offsetTop
          }

          if (!content && classes.includes('modal-card-body')) {
            content = element
            element = null
          }

          if (lastElement && element === lastElement.offsetParent) {
            top += element.offsetTop
          }

          if (element) {
            lastElement = element
            element = element.parentElement
          }
        }

        top -= 10

        if (content) {
          content.scrollTo({ top, behavior: 'smooth' })
        } else {
          window.scrollTo({ top, behavior: 'smooth' })
        }
      }
    },
    metaLink (links) {
      const findIndex = links.findIndex(x => x.hreflang === 'x-default')
      if (findIndex >= 0) {
        this.$delete(links, findIndex)
      }

      if (process.env.seoLang) {
        try {
          const seoLang = JSON.parse(process.env.seoLang)
          const keys = Object.keys(seoLang)

          links.forEach((element) => {
            if (
              keys.includes(element.hreflang) &&
              seoLang[element.hreflang] &&
              Array.isArray(seoLang[element.hreflang]) &&
              seoLang[element.hreflang].length
            ) {
              seoLang[element.hreflang].forEach((lang, index) => {
                if (index === 0) {
                  element.hreflang = lang
                } else {
                  const newElement = this._clone(element)
                  newElement.hreflang = lang
                  links.push(newElement)
                }
              })
            }
          })
        } catch (e) {

        }
      }
      return links
    },
    async initWebSocket () {
      try {
        if (
          !this.$nuxt.$store.state.websocket.isWebSocketConnecting &&
          !this.$nuxt.$store.state.websocket.isWebSocketConnection
        ) {
          this.$store.dispatch('websocket/setIsWebSocketConnecting', true)
          let params = null
          const token = this.$cookies.get('token')
          const sessionAPI = this.$cookies.get('sessionAPI')
          if (token) {
            params = '?token=' + token
          } else if (sessionAPI) {
            params = '?PHPSESSID=' + sessionAPI
          } else {
            console.log('GET SESSIONID FROM API')
            await this.$api.auth.session()
              .then((res) => {
                this.$cookies.set('sessionAPI', res.PHPSESSID, { path: '/', maxAge: 60 * 60 * 24 * 30 })
                params = '?PHPSESSID=' + res.PHPSESSID
              })
              .catch(() => {
                this.$store.dispatch('websocket/setIsWebSocketConnecting', false)
                setTimeout(() => {
                  this.initWebSocket()
                }, 2000)
              })
          }

          console.log(`%c${new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' })} %cCONNECTING TO WEBSOCKET`, 'color: #CEC4fA;', 'background: #6638AC; font-size: 0.75rem; color: #CEC4fA;', params)
          this.connectWebSocket(params)
        }
      } catch (e) {
        console.error(`${new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' })} ERROR CONNECTION WEBSOCKET`)
      }
    },
    connectWebSocket (token) {
      const url = process.env.webSocketUrl
      const wb = new WebSocket(url + token)

      this.$store.dispatch('websocket/connection', wb)

      wb.onopen = (webSocket) => {
        // await webSocket.currentTarget.send(JSON.stringify({ action: 'join' }))
        console.log(`%c${new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' })} %cCONNECTION TO WEBSOCKET`, 'color: #CEC4fA;', 'background: #4500ad; font-size: 0.75rem; color: #CEC4fA;')
        this.$store.dispatch('websocket/setIsWebSocketConnection', true)
        this.$store.dispatch('websocket/setIsWebSocketConnecting', false)
        this.$store.dispatch('websocket/onMessage')
        this.$store.dispatch('websocket/send', { action: 'join' })
      }

      wb.onclose = (e) => {
        const dataClose = {
          reason: e.reason,
          code: e.code,
          wasClean: e.wasClean,
          target: e?.target?.url
        }
        console.warn(`${new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' })} CLOSE WEBSOCKET`, dataClose)
        this.$store.dispatch('websocket/setIsWebSocketConnection', false)
        this.$store.dispatch('websocket/setIsWebSocketConnecting', false)
        setTimeout(function () {
          if (!this.$nuxt.$store.state.websocket.isWebSocketConnection) {
            this.$nuxt.initWebSocket()
            this.$nuxt.$store.dispatch('websocket/sendLastMessage')
          }
        }, 2000)
      }
      wb.onerror = (e) => {
        console.error(`${new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' })} ERROR WEBSOCKET`, e)
        this.$store.dispatch('websocket/setIsWebSocketConnection', false)
        this.$store.dispatch('websocket/setIsWebSocketConnecting', false)
      }
    }
  }
}
</script>
