import Vue from 'vue'
import Buefy from 'buefy'

Vue.use(Buefy, {
  "css": false,
  "materialDesignIcons": false,
  "materialDesignIconsHRef": "https://cdn.jsdelivr.net/npm/@mdi/font@5.8.55/css/materialdesignicons.min.css",
  "async": true,
  "defaultIconPack": "core-icons",
  "customIconPacks": {
    "core-icons": {
      "sizes": {
        "default": "is-size-5",
        "is-small": "is-size-7",
        "is-medium": "is-size-3",
        "is-large": "is-size-1"
      },
      "iconPrefix": "core-icons-"
    }
  }
})