<template>
  <div>
    <b-icon
      v-if="existChildren"
      :icon="open ? 'chevron-down' : 'chevron-right'"
      :class="{'is-invisible': category.children.length <= 0 }"
      class="pointer"
      @click.native="open = !open"
    />
    <nuxt-link
      :to="localePath({
        name: 'products-category',
        params: {
          categoryName: toSlugTitle(category.description),
          categoryId: category.id
        },
        query
      })"
      @click.native="startLoading()"
    >
      {{ category.description.name }}
    </nuxt-link>

    <div v-show="open">
      <filters-categories
        v-if="category.children.length > 0"
        :categories="category.children"
        :margin="margin"
        @loading="$emit('loading', true)"
        @open="opening"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    category: {
      type: Object,
      required: true
    },
    existChildren: {
      type: Boolean
    },
    margin: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      open: false,
      availableCategories: []
    }
  },
  computed: {
    query () {
      const query = this._clone(this.$route.query)
      delete query.page
      return query
    }
  },
  watch: {
    '$route.params.categoryId' () {
      this.checkOpening()
    }
  },
  created () {
    this.checkOpening()
  },
  methods: {
    startLoading () {
      if (!this.checkCurrentCategory() && this.$route.params.categoryId) {
        this.$emit('loading', true)
      }
    },
    checkOpening () {
      if (this.checkCurrentCategory()) {
        this.opening()
      }
    },
    opening () {
      this.open = true
      this.$emit('open', true)
    },
    checkCurrentCategory () {
      return this.$route.params.categoryId && Number(this.$route.params.categoryId) === this.category.id
    }
  }
}
</script>
