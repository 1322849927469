<template>
  <div>
    <template v-if="logo === 'schuette'">
      <logo-schuette />
    </template>
    <template v-if="logo === 'rzaska'">
      <logo-rzaska />
    </template>
  </div>
</template>

<script>
import logoSchuette from '~/static/images/schuette-logo-white.svg?inline'
import logoRzaska from '~/static/images/logo-rzaska.svg?inline'

export default {
  components: { logoSchuette, logoRzaska },
  data () {
    return {
      logo: process.env.project
    }
  }
}
</script>

<style scoped>
svg {
  width: 72px;
  margin-right: 8px;
}
</style>
