<template>
  <div v-if="show" class="cookieAlert">
    <div class="box">
      <div class="columns is-vcentered">
        <div class="column">
          {{ $t('cookieAlert.message') }}
        </div>
        <div class="column is-narrow">
          <b-button type="is-green" :aria-label="$t('cookieAlert.btn')" @click="saveCookieAlert()">
            {{ $t('cookieAlert.btn') }}
          </b-button>
          <b-button type="is-light" icon-left="clear" :aria-label="$t('actions.close')" @click="saveCookieAlert()" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {
      show: null
    }
  },
  mounted () {
    const cookieAlert = this.$cookies.get('cookieAlert')
    if (!cookieAlert) {
      this.show = true
    } else {
      this.show = false
    }
  },
  methods: {
    saveCookieAlert () {
      this.$cookies.set('cookieAlert', true, { path: '/', maxAge: 60 * 60 * 24 * 365 })
      this.show = false
    }
  }
}
</script>
