<template>
  <div class="avatar-container" :class="[{ 'icon-right': iconRight }]">
    <div class="circle" :class="[{ profile: profile }, `size-${size}`, {margin : noMargin} ]">
      <div v-if="url">
        <img :src="url" alt="avatar">
      </div>
      <div v-else-if="name" class="initials">
        {{ createInitials(name) }}
      </div>
      <div v-else>
        KL
      </div>
      <span v-if="online" class="avatar-online" />
      <b-icon v-if="iconBottomRight" :icon="iconBottomRight" class="icon-bottom-right" />
    </div>
    <div class="name" :class="[{ profile: profile }]">
      <span v-if="profile">
        {{ $t('header.hello', { user: name }) }}
      </span>
      <span v-if="target === 'review'">
        {{ name }}
      </span>
      <!-- <span v-else>
        {{ name }} <nobr v-if="quantity" class="group-deal-quantity">{{ $tc('groupDeal.timeline.user', quantity) }}</nobr>
      </span> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: null
    },
    avatar: {
      type: Object,
      default: null
    },
    avatarUrl: {
      type: String,
      default: null
    },
    profile: {
      type: Boolean
    },
    quantity: {
      type: Number,
      default: null
    },
    iconRight: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'default'
    },
    online: {
      type: Boolean,
      default: false
    },
    iconBottomRight: {
      type: String,
      default: ''
    },
    target: {
      type: String,
      default: ''
    },
    noMargin: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    url () {
      return this.avatar && this.avatar.sizes ? this.avatar.sizes.small : this.avatarUrl
    }
  },
  methods: {
    createInitials (string) {
      const words = string.split(' ')
      if (words.length > 1 && words[0].length && words[1].length) {
        return (words[0][0] + words[1][0]).toUpperCase()
      } else {
        return words[0].substring(0, 2).toUpperCase()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$avatar-online-bg-color: #61D373;

.avatar-container {
  display: inline-flex;
  align-items: center;

  .circle {
    position: relative;
    border-radius: 50%;
    background-color: #eeeeee;
    // border: 1px solid white;

    &.size-small {
      &>div {

        width: 24px;
        height: 24px;
        font-size: 12px;
      }


      .avatar-online {
        width: 8px;
        height: 8px;
      }


      .icon-bottom-right {
        width: 12px;
        height: 12px;
        position: absolute;
        bottom: 0;
        right: -4px;


        :deep(i) {
          width: 12px;
          height: 12px;
          font-size: 14px !important;
        }
      }
    }

    &.size-big {
      &>div {
        width: 48px;
        height: 48px;
        font-size: 24px;
      }

      .avatar-online {
        width: 8px;
        height: 8px;
      }

      .icon-bottom-right {
        width: 24px;
        height: 24px;
        position: absolute;
        bottom: 0;
        right: -16px;

        :deep(i) {
          width: 24px;
          height: 24px;
        font-size: 26px !important;
        }
      }
    }
    &.margin{
      margin-right: 0 !important;
    }

    &.profile span {
      width: 38px;
      height: 38px;
    }

    &>div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2.25rem;
      height: 2.25rem;


      img {
        object-fit: cover;
        border-radius: 50%;
      }
    }

    .avatar-online {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 12px;
      height: 12px;
      border-radius: 6px;
      background-color: $avatar-online-bg-color;
      border: 1px solid white;
    }

    .icon-bottom-right {
      width: 20px;
      height: 20px;
      position: absolute;
      bottom: 0;
      right: -10px;
    }
  }

  .name {
    font-weight: 600;

    &.profile {
      font-weight: 400;
    }
  }

  .group-deal-quantity {
    font-weight: 400;
  }

  &.icon-right {
    flex-direction: row-reverse;

    .circle {
      margin-right: 0;
      margin-left: 0.75rem;
    }
  }

}</style>

