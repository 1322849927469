import toSlug from './toSlug'

export default function toSlugTitle (description) {
  const maxLength = 100

  let text = description && description.name ? description.name : 'product'

  if (text.length > maxLength) {
    const count = text.substring(0, maxLength).split(' ').length
    const newText = text.split(' ')
    newText.length = count
    text = newText.join(' ')
  }

  return toSlug(text)
}
