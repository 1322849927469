var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.existChildren)?_c('b-icon',{staticClass:"pointer",class:{'is-invisible': _vm.category.children.length <= 0 },attrs:{"icon":_vm.open ? 'chevron-down' : 'chevron-right'},nativeOn:{"click":function($event){_vm.open = !_vm.open}}}):_vm._e(),_vm._v(" "),_c('nuxt-link',{attrs:{"to":_vm.localePath({
      name: 'products-category',
      params: {
        categoryName: _vm.toSlugTitle(_vm.category.description),
        categoryId: _vm.category.id
      },
      query: _vm.query
    })},nativeOn:{"click":function($event){return _vm.startLoading()}}},[_vm._v("\n    "+_vm._s(_vm.category.description.name)+"\n  ")]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.open),expression:"open"}]},[(_vm.category.children.length > 0)?_c('filters-categories',{attrs:{"categories":_vm.category.children,"margin":_vm.margin},on:{"loading":function($event){return _vm.$emit('loading', true)},"open":_vm.opening}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }