<template>
  <div>
    <div v-if="!noIcon" class="menu-item">
      <span class="menu-title has-text-weight-semibold">
        {{ $t('cart.title') }} ({{ getCount }})
      </span>
      <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38"><title>Cart</title><circle cx="19" cy="19" r="19" fill="#e32f31"/><path d="M24.17,21.89h-8a.76.76,0,0,1-.74-.6l-1.86-9L11,12.21a.75.75,0,0,1-.72-.78.74.74,0,0,1,.78-.72l3.17.14a.74.74,0,0,1,.7.59l.6,2.87h10a.79.79,0,0,1,.58.28.76.76,0,0,1,.15.63l-1.27,6.07A.76.76,0,0,1,24.17,21.89Zm-7.41-1.5h6.8l1-4.58H15.81Z" fill="#fff"/><path d="M23,28.16a2,2,0,1,1,2-2A2,2,0,0,1,23,28.16Zm0-2.5a.5.5,0,0,0-.5.5.5.5,0,0,0,1,0A.5.5,0,0,0,23,25.66Z" fill="#fff"/><path d="M17,28.16a2,2,0,1,1,2-2A2,2,0,0,1,17,28.16Zm0-2.5a.5.5,0,0,0-.5.5.5.5,0,0,0,1,0A.5.5,0,0,0,17,25.66Z" fill="#fff"/></svg>
    </div>
    <div v-else class="button is-primary is-fullwidth" @click.stop="$store.dispatch('page/isOpenedCartToggle')">
      {{ $t('cart.title') }} ({{ getCount }})
    </div>
  </div>
</template>

<script>
export default {
  props: {
    noIcon: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getCart () {
      return this.$store.state.page.cart
    },
    getCount () {
      return this.getCart && this.getCart.quantity ? this.getCart.quantity : 0
    }
  },
  methods: {
    toggleOpenCart () {
      this.$store.dispatch('page/isOpenedCartToggle')
    }
  }
}
</script>
