export default {
  nuxtServerInit ({ dispatch, state }, { req }) {
    if (!process.client) {
      // PAGE LOCALE
      dispatch('page/locale', state.i18n.locale)
      // AUTH COOKIE
      if (this.$cookies.get('token') && this.$cookies.get('refreshToken')) {
        const token = this.$cookies.get('token')
        const refreshToken = this.$cookies.get('refreshToken')

        if (token && refreshToken) {
          dispatch('auth/set', [{ token, refreshToken }, true])
        }
      }
    }
  }
}
