<script>
export default {
  methods: {
    projectEventProductView (product, userId) {
      if (this.$ga && process.env.googleTagManager === 'AW-666487120') {
        const item = {
          id: product.id,
          ecomm_prodid: product.id,
          google_business_vertical: 'retail'
        }

        if (product.categories && product.categories.length) {
          item.category = product.categories[0].description.name
        }

        this.$gtag('event', 'view_item', {
          send_to: 'AW-666487120',
          items: [item],
          value: product.summary.price,
          user_id: userId
        })
      }
    },

    projectEventProductAddToCart (product, prices, quantity) {
      if (this.$ga && process.env.googleTagManager === 'AW-666487120') {
        this.$gtag('event', 'add_to_cart', {
          send_to: 'AW-666487120',
          items: [
            {
              id: product.id,
              ecomm_prodid: product.id,
              google_business_vertical: 'retail'
            }
          ],
          value: prices.totalPrice
        })
      }
    },

    projectEventRemoveProductFromCart (product) {

    },

    projectEventCheckoutView (orders) {

    },

    projectEventCheckoutOptionPayment (option) {

    },

    projectEventCheckoutOptionShipping (option) {

    },

    projectEventPurchaseCheckout (orders) {
      if (this.$ga) {
        orders.forEach((order) => {
          if (order.totals && order.totals.length > 0) {
            const find = order.totals.find(x => x.type === 'finalTotal')
            if (find && process.env.googleTagManager === 'AW-666487120') {
              this.$gtag('event', 'conversion', {
                send_to: 'AW-666487120/lIQ3CPmv5tMBENCS570C',
                value: find.price,
                currency: order.currency,
                transaction_id: order.orderId
              })

              const items = []

              order.products.forEach((product) => {
                items.push({
                  id: product.offerProductId,
                  ecomm_prodid: product.offerProductId,
                  google_business_vertical: 'retail'
                })
              })

              this.$gtag('event', 'purchase', {
                send_to: 'AW-666487120',
                items,
                value: find.price
              })
            }
          }
        })
      }
    },

    projectEventPurchaseSuccessPage (orders) {

    }

  }
}
</script>
