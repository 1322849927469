<template>
  <div class="video">
    <video
      v-if="video"
      ref="video"
      class="lazy"
      muted
      playsinline
      :loop="true"
      :autoplay="true"
      :controls="false"
      :poster="poster"
    >
      <source v-if="videoMov" :data-src="videoMov.video" type="video/quicktime">
      <source :data-src="video.video" :type="type">
      {{ alt || 'Video' }}
    </video>
  </div>
</template>

<script>
import lazyVideo from 'marketplace-front-core/utils/lazyVideo'

export default {
  props: {
    video: {
      type: Object,
      required: true
    },
    videoMov: {
      type: Object,
      default: null
    },
    alt: {
      type: String,
      default () {
        return null
      }
    },
    type: {
      type: String,
      default: 'video/mp4'
    },
    poster: {
      type: String,
      default: null
    }
  },
  mounted () {
    this.$nextTick(() => {
      lazyVideo()
    })

    // if (this.video) {
    //   this.$refs.video.addEventListener('playing', () => this.playing())
    // }
  }
  // beforeDestroy () {
  //   if (this.$refs.video) {
  //     this.$refs.video.removeEventListener('playing', () => this.playing())
  //   }
  // },
  // methods: {
  //   playing () {
  //     if (this.video) {
  //       this.$refs.video.playbackRate = 1
  //       this.$refs.video.play()
  //     }
  //   }
  // }
}
</script>
