<template>
  <section class="section">
    <div class="content has-text-grey has-text-centered">
      <p>
        <b-icon
          :icon="smile ? 'emoticon' : 'emoticon-sad'"
          size="is-large"
        />
      </p>
      <p>{{ $t('fields.empty') }}</p>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    smile: {
      type: Boolean
    }
  }
}
</script>
