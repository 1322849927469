<template>
  <ul :class="'m-l-' + margin">
    <li v-for="(category, index) in categories" :key="index" class="is-flex">
      <filters-categories-row
        :category="category"
        :exist-children="existChildren"
        :margin="margin + setMargin"
        @loading="$emit('loading', true)"
        @open="$emit('open', true)"
      />
    </li>
  </ul>
</template>

<script>
import FiltersCategoriesRow from 'marketplace-front-core/components/filters/FiltersCategoriesRow'

export default {
  components: {
    FiltersCategoriesRow
  },
  props: {
    categories: {
      type: Array,
      required: true
    },
    startingParent: {
      type: Boolean
    }
  },
  data () {
    return {
      setMargin: 20
    }
  },
  computed: {
    existChildren () {
      return this.categories.some(x => x.children.length > 0)
    },
    margin () {
      return this.startingParent
        ? 0
        : this.existChildren
          ? this.setMargin
          : this.setMargin + 20
    }
  }
}
</script>
