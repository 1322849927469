const middleware = {}

middleware['languages'] = require('../middleware/languages.js')
middleware['languages'] = middleware['languages'].default || middleware['languages']

middleware['productScroll'] = require('../middleware/productScroll.js')
middleware['productScroll'] = middleware['productScroll'].default || middleware['productScroll']

middleware['query'] = require('../middleware/query.js')
middleware['query'] = middleware['query'].default || middleware['query']

middleware['route'] = require('../middleware/route.js')
middleware['route'] = middleware['route'].default || middleware['route']

middleware['seo'] = require('../middleware/seo.js')
middleware['seo'] = middleware['seo'].default || middleware['seo']

export default middleware
