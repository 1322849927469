export const coreState = () => ({
  webSocket: null,
  unreadMessage: null,
  isWebSocketConnection: false,
  isWebSocketConnecting: false,
  lastMessage: null,
  connectedTicket: null,
  limitMessage: 20,
  loadingTicket: false,
  loadingTicketList: false,
  loadingTicketListMore: false,
  loadingMessageMore: false,
  loadingCreateTicket: false,
  loadingAssignEmployees: false,
  loadingCloseTicket: false,
  loadingChangeOrderId: false
})

export const coreGetters = {
}

export const coreMutations = {
  setWebSocket (store, data) {
    store.webSocket = data
  },
  setUnreadMessage (store, data) {
    store.unreadMessage = data
  },
  setIsWebSocketConnection (store, data) {
    store.isWebSocketConnection = data
  },
  setIsWebSocketConnecting (store, data) {
    store.isWebSocketConnecting = data
  },
  setLastMessage (store, data) {
    store.lastMessage = data
  },
  setConnectedTicket (store, data) {
    store.connectedTicket = data
  },
  setLoadingTicket (store, data) {
    store.loadingTicket = data
  },
  setLoadingTicketList (store, data) {
    store.loadingTicketList = data
  },
  setLoadingTicketListMore (store, data) {
    store.loadingTicketListMore = data
  },
  setLoadingMessageMore (store, data) {
    store.loadingMessageMore = data
  },
  setLoadingCreateTicket (store, data) {
    store.loadingCreateTicket = data
  },
  setLoadingAssignEmployees (store, data) {
    store.loadingAssignEmployees = data
  },
  setLoadingCloseTicket (store, data) {
    store.loadingCloseTicket = data
  },
  setLoadingChangeOrderId (store, data) {
    store.loadingChangeOrderId = data
  }
}

export const coreActions = {
  connection ({ commit }, webSocket) {
    commit('setWebSocket', webSocket)
  },

  close ({ commit, state, dispatch }) {
    console.log('SET CLOSE WEBSOCKET')
    const params = {
      action: 'close-connection'
    }

    dispatch('send', params)

    commit('setUnreadMessage', null)
    state.webSocket.close()

    commit('setIsWebSocketConnection', false)
  },

  // onopen ({ commit, state, dispatch }) {
  //   state.webSocket.onopen = async (wb) => {
  //     await wb.currentTarget.send(JSON.stringify({ action: 'join' }))
  //     console.log('weszlo', wb.currentTarget)
  //     commit('setIsWebSocketConnection', true)
  //   }
  // },

  setIsWebSocketConnection ({ commit }, type) {
    commit('setIsWebSocketConnection', type)
  },

  setIsWebSocketConnecting ({ commit }, type) {
    commit('setIsWebSocketConnecting', type)
  },

  ticketsList ({ dispatch }, filter) {
    const params = {
      action: 'ticket-list-specific-user',
      requiredId: filter.requiredId,
      limit: filter.limit,
      filter: filter.filter || null,
      myTickets: filter.myTickets || false, // Moje
      othersTickets: filter.othersTickets || false, // Innych
      unassigned: filter.unassigned || false, // Nieprzepisane
      ticketOpen: filter.ticketOpen || false, // Otwarte
      ticketClosed: filter.ticketClosed || false, // Zamknięte
      ticketArchived: filter.ticketArchived || false, // Archiwum
      lang: filter.lang || [],
      ticketsCount: filter.ticketsCount
    }

    dispatch('send', params)

    if (filter.ticketsCount === 0) {
      dispatch('changeLoadingTicketList', true)
    } else {
      dispatch('changeLoadingTicketListMore', true)
    }
  },

  unreadMessage ({ commit }, params) {
    commit('setUnreadMessage', params)
  },

  assignOrder ({ dispatch }, data) {
    const params = {
      action: 'assign-order',
      orderId: data.orderId,
      ticketId: data.ticketId
    }

    dispatch('send', params)

    dispatch('changeLoadingChangeOrderId', true)
  },

  ticketData ({ dispatch }, ticketId) {
    const params = {
      action: 'ticket-data',
      ticketId
    }

    dispatch('send', params)
  },

  send ({ state, dispatch, getters }, params) {
    if (params) {
      if (!(state.isWebSocketConnection && state.webSocket.readyState === 1)) {
        dispatch('waitForWebSocketConnection', params)
      } else {
        dispatch('functionSend', params)
      }
    }
  },

  functionSend ({ state }, params) {
    params['Content-Language'] = this.state.i18n.locale
    state.webSocket.send(JSON.stringify(params))
    console.log(`%c${new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' })} %cSEND TO API:%c ${params.action}`, 'color: #C4D9FA;', 'background: #3865AC; font-size: 0.75rem; color: #C4D9FA;', 'color: #C4D9FA;', params)
  },

  waitForWebSocketConnection ({ state, dispatch }, params) {
    setTimeout(() => {
      if (state.isWebSocketConnection && state.webSocket.readyState === 1) {
        dispatch('functionSend', params)
      } else {
        dispatch('waitForWebSocketConnection', params)
      }
    }, 50)
  },

  sendMessage ({ dispatch }, { message, attachments = [] }) {
    const params = {
      action: 'message',
      message,
      attachments
    }
    dispatch('send', params)
  },

  // if WS was restarting and send second time message
  sendLastMessage ({ dispatch, state }) {
    if (state.connectedTicket !== null) {
      dispatch('connectToRoom', {
        ticketId: state.connectedTicket,
        ticketsCount: 0
      })
    }

    dispatch('send', state.lastMessage)
  },

  connectToRoom ({ commit, dispatch, state }, filter) {
    const params = {
      action: 'connect',
      ticketId: filter.ticketId,
      limit: state.limitMessage,
      ticketsCount: filter.ticketsCount
    }

    commit('setConnectedTicket', filter.ticketId)

    dispatch('send', params)

    dispatch('changeLoadingTicket', true)
  },

  createRoom ({ dispatch }, { type, id }) {
    const params = {
      action: 'create-ticket',
      type,
      id,
      lang: this.state.i18n.locale
    }
    dispatch('send', params)

    dispatch('changeLoadingCreateTicket', true)
  },

  typing ({ dispatch }, action) {
    dispatch('send', action)
  },

  closeRoom ({ dispatch }, id) {
    const params = {
      action: 'ticket-status-change',
      ticketIds: [id],
      newStatus: 'CLOSED'
    }
    dispatch('send', params)

    dispatch('changeLoadingCloseTicket', true)
  },

  assignRoomTo ({ dispatch }, { assignTo, unassignFrom = [], ticketId }) {
    const params = {
      action: 'ticket-assign-to',
      assignTo,
      ticketId,
      unassignFrom
    }

    dispatch('send', params)

    dispatch('changeLoadingAssignEmployees', true)
  },

  editMessage ({ dispatch }, { messageId, newMessage, attachments }) {
    const params = {
      action: 'edit-message',
      messageId,
      newMessage,
      attachments
    }

    dispatch('send', params)
  },

  switchTicketLanguage ({ dispatch }, { ticketId, lang }) {
    const params = {
      action: 'change-ticket-lang',
      ticketId,
      lang
    }
    dispatch('send', params)
  },

  loadMoreMessages ({ dispatch, state }, { lastMessageId }) {
    const params = {
      action: 'message-history',
      lastMessageId,
      limit: state.limitMessage
    }
    dispatch('send', params)

    dispatch('changeLoadingMessageMore', true)
  },

  onMessage ({ commit, state, getters }) {
    state.webSocket.onmessage = (message) => {
      const data = JSON.parse(message.data)
      console.log(`%c${new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' })} %cGET FROM API OTHER:%c ${data.type}`, 'color: #C4EFD8;', 'background: #30714E; font-size: 0.75rem; color: #C4EFD8;', 'color: #C4EFD8;', data)
      switch (data.type) {
        case 'count-unread':
          commit('setUnreadMessage', data)
          break
        case 'JWT-token-info':
          // dispatch('refreshToken', data)
          break
        case 'message':
          break
        case 'message-history':
          break
        case 'user-started-typing':
          break
        case 'user-stopped-typing':
          break
        // case 'check-ticket-list':
        case 'ticket-list':
          break
        case 'ticket-data':
          break
        case 'info-ticket-data':
          break
        case 'assign-order':
          break
        case 'eventLeft':
          break
        case 'eventJoined':
          break
        case 'message-error':
          break
        case 'edited-message-info':
          break
        case 'edit-message':
          break
        case 'employees-online':
          break
        default:
          console.warn(`${new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' })} UNKNOWN TYPE OTHER: `, data.type, data)
      }
    }
  },

  // async refreshToken (message) {
  //   if (message.message === 'Expired JWT Token') {
  //     this.$store.dispatch('websocket/close')
  //     await this.$api.auth.refreshToken(this.$store.state.auth.refreshToken)
  //       .then((res) => {
  //         this.$store.dispatch('auth/set', [res, false])
  //       }).then(() => {
  //         setTimeout(() => {
  //           this._vm.initWebSocket()
  //         }, '5000')
  //       })
  //   }
  // },

  changeLoadingTicket ({ commit }, data) {
    // console.log('loading ticket', data)
    commit('setLoadingTicket', data)
  },

  changeLoadingTicketList ({ commit }, data) {
    // console.log('loading ticket list', data)
    commit('setLoadingTicketList', data)
  },

  changeLoadingTicketListMore ({ commit }, data) {
    // console.log('loading ticket list more', data)
    commit('setLoadingTicketListMore', data)
  },

  changeLoadingMessageMore ({ commit }, data) {
    // console.log('loading message more', data)
    commit('setLoadingMessageMore', data)
  },

  changeLoadingCreateTicket ({ commit }, data) {
    // console.log('loading create ticket', data)
    commit('setLoadingCreateTicket', data)
  },

  changeLoadingAssignEmployees ({ commit }, data) {
    // console.log('loading assign employees', data)
    commit('setLoadingAssignEmployees', data)
  },

  changeLoadingCloseTicket ({ commit }, data) {
    // console.log('loading close ticket', data)
    commit('setLoadingCloseTicket', data)
  },

  changeLoadingChangeOrderId ({ commit }, data) {
    // console.log('loading change order', data)
    commit('setLoadingChangeOrderId', data)
  }
}
