<template>
  <div class="category-tiles">
    <!-- {{ PleatedBlindsVideo }} -->
    <a :href="localePath('categories-pleated-blinds')" @click.prevent="redirect(localePath('categories-pleated-blinds'))">
      <category-tile :name="$t('project.layout.footer.menu.pb')" :rating-average="4.9" tabindex="0">
        <template #icon>
          <lazy-video :video="{video: PleatedBlindsVideo}" :video-mov="{video: PleatedBlindsVideoMov}" type="video/webm" alt="pleated blinds" />
        </template>
      </category-tile>
    </a>
    <a :href="localePath('categories-roller-blinds')" @click.prevent="redirect(localePath('categories-roller-blinds'))">
      <category-tile :name="$t('project.layout.footer.menu.rb')" :rating-average="4.9" tabindex="0">
        <template #icon>
          <lazy-video :video="{video: RollerBlindsVideo}" :video-mov="{video: RollerBlindsVideoMov}" type="video/webm" alt="roller blinds" />
          <!-- <roller-blinds-icon /> -->
        </template>
      </category-tile>
    </a>
    <a :href="localePath('categories-curtains')" @click.prevent="redirect(localePath('categories-curtains'))">
      <category-tile :name="$t('project.layout.footer.menu.ct')" :rating-average="5" tabindex="0">
        <template #icon>
          <lazy-video :video="{video: CurtainsVideo}" :video-mov="{video: CurtainsVideoMov}" type="video/webm" alt="curtains" />
          <!-- <curtains-icon /> -->
        </template>
      </category-tile>
    </a>
    <a :href="localePath('categories-relax')" @click.prevent="redirect(localePath('categories-relax'))">
      <category-tile :name="$t('project.layout.footer.menu.sb')" :rating-average="5" tabindex="0">
        <template #icon>
          <lazy-video :video="{video: RelaxVideo}" :video-mov="{video: RelaxVideoMov}" type="video/webm" alt="relax" />
          <!-- <accessories-icon /> -->
        </template>
      </category-tile>
    </a>
  </div>
</template>

<script>
import LazyVideo from 'marketplace-front-core/components/shared/LazyVideo.vue'
import CategoryTile from './CategoryTile'
// import PleatedBlindsIcon from '~/assets/images/category/pb.svg?inline'
// import RollerBlindsIcon from '~/assets/images/category/rb.svg?inline'
// import CurtainsIcon from '~/assets/images/category/cr.svg?inline'
// import AccessoriesIcon from '~/assets/images/category/ac.svg?inline'
import PleatedBlindsVideo from '~/static/images/pleated-blinds-video.webm'
import PleatedBlindsVideoMov from '~/static/images/pleated-blinds-video.mov'
import RollerBlindsVideo from '~/static/images/roller-blinds-video.webm'
import RollerBlindsVideoMov from '~/static/images/roller-blinds-video.mov'
import CurtainsVideo from '~/static/images/curtains-video.webm'
import CurtainsVideoMov from '~/static/images/curtains-video.mov'
import RelaxVideo from '~/static/images/relax-video.webm'
import RelaxVideoMov from '~/static/images/relax-video.mov'

export default {
  name: 'CategoryTiles',
  components: {
    CategoryTile,
    LazyVideo
    // PleatedBlindsIcon,
    // RollerBlindsIcon,
    // CurtainsIcon,
    // AccessoriesIcon
  },
  data () {
    return {
      PleatedBlindsVideo,
      PleatedBlindsVideoMov,
      RollerBlindsVideo,
      RollerBlindsVideoMov,
      CurtainsVideo,
      CurtainsVideoMov,
      RelaxVideo,
      RelaxVideoMov
    }
  },
  methods: {
    redirect (link) {
      this.$store.dispatch('page/isMenuOpen', false)

      this.$router.push(link)
    }
  }
}
</script>

<style lang="scss" scoped>
.category-tiles {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 50px;
  @include touch {
    flex-direction: column;
    gap: 10px;
    margin-bottom: 32px;
  }
  a {
    width: 100%;
    color: $text_color;
  }
}
</style>
